// 工具函数
export const getCookie = (name) => {
  name += '=';
  for (var ca = document.cookie.split(/;\s*/), i = ca.length - 1; i >= 0; i--)
    if (!ca[i].indexOf(name))
      return ca[i].replace(name, '');
};

//  bytes to M 
export const BytesToM = (value) => {
  if (!value) return;
  return Number(value) / 1024 / 1024;
};

//  bytes to G
export const BytesToG = (value) => {
  if (!value) return;
  return Number(value) / 1024 / 1024 / 1024;
}; 

//  toFixed
export const toFixed = (value) => {
  if (!value) return;
  return value.toFixed(2);
}; 