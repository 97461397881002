import Vue from 'vue';
import Router from 'vue-router';

// 用户登陆
import Login from './views/User/Login.vue';
// 用户登陆后主布局
import Layout from './components/Layout/Layout.vue';

// 资源管理
import ResourceLayout from './components/Layout/ResourceLayout.vue';
import AgentAccountManage from './views/resource/AccountManage.vue'; // 资源管理-子账号管理
import DeviceList from './views/resource/DeviceList.vue'; // 资源管理-矿机管理-设备列表
import DeviceListForChild from './views/resource/DeviceListForChild.vue'; // 资源管理-矿机管理-设备状态收益
import DeviceWatch from './views/resource/DeviceWatch.vue'; // 资源管理-矿机管理-单设备监控
import DialStatusV2 from './views/resource/DialStatus.vue'; // 资源管理-"软路由-拨号状态"
import ResourceIndex from './views/resource/ResourceIndex.vue'; // 资源管理-首页
import CommitDevice from './views/resource/CommitDevice.vue'; // 资源管理-矿机管理-设备提交
import DialConfig from './views/resource/DialConfig.vue'; // 资源管理-矿机管理-拨号配置管理
import DeviceDataView from './views/resource/DeviceDataView.vue'; // 资源管理-矿机管理-矿机数据总览
import AuditDevice from './views/resource/AuditDevice.vue'; // 资源管理-矿机管理-待提交设备审核

// 账号设置
import AccountLayout from './components/Layout/AccountLayout.vue';
import PasswordManage from './views/account/PasswordManage.vue'; // 账号设置-修改密码

// 我的钱包
import WalletLayout from './components/Layout/WalletLayout.vue';
import PaymentManage from './views/wallet/PaymentManage.vue'; // 我的钱包-收款方式
import ContactV2 from './views/wallet/Contact.vue'; // 我的钱包-实名认证

// 工单管理
import OrderManagement from './views/resource/OrderManagement.vue'; // 工单管理

Vue.use(Router);

const title = '游兔云';

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: 'resource',
        component: ResourceLayout,
        children: [
          {
            meta: { title },
            path: 'index',
            name: 'resourceIndex',
            component: ResourceIndex,
          },
          {
            meta: { title },
            path: 'accountManage',
            name: 'accountManage',
            component: AgentAccountManage,
          },
          {
            meta: { title },
            path: 'deviceList',
            name: 'deviceList',
            component: DeviceList,
          },
          {
            meta: { title },
            path: 'deviceWatch',
            name: 'deviceWatch',
            component: DeviceWatch,
          },
          {
            meta: { title },
            path: 'dialStatus',
            name: 'DialStatus',
            component: DialStatusV2,
          },
          {
            meta: { title },
            path: 'deviceListForChild',
            name: 'deviceListForChild',
            component: DeviceListForChild,
          },
          {
            meta: { title },
            path: 'commitDevice',
            name: 'commitDevice',
            component: CommitDevice,
          },
          {
            path: 'dialConfig',
            name: 'DialConfig',
            meta: { title },
            component: DialConfig,
          },
          {
            path: 'deviceDataView',
            name: 'deviceDataView',
            meta: { title },
            component: DeviceDataView,
          },
          {
            path: 'auditDevice',
            name: 'auditDevice',
            meta: { title },
            component: AuditDevice,
          },
          {
            path: 'orderManagement',
            name: 'orderManagement',
            meta: { title },
            component: OrderManagement,
          },
        ],
      },
      {
        path: 'wallet',
        component: WalletLayout,
        children: [
          {
            meta: { title },
            path: 'paymentManage',
            name: 'paymentManage',
            component: PaymentManage,
          },
          {
            meta: { title },
            path: 'contact',
            name: 'contact',
            component: ContactV2,
          },
        ],
      },
      {
        path: 'account',
        component: AccountLayout,
        children: [
          {
            meta: { title },
            path: 'passwordManage',
            name: 'passwordManage',
            component: PasswordManage,
          },
        ],
      },
      {
        path: '/*',
        meta: { title },
        redirect: '/resource/index',
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    meta: { title },
    component: Login,
  },
  {
    path: '/*',
    meta: { title },
    redirect: '/resource/index',
  },
];

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
