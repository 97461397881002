<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>资源管理</el-breadcrumb-item>
            <el-breadcrumb-item>单设备监控</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card shadow="never" class="box-card" v-loading="isLoading">
            <h4>监控</h4>
            <span class="margin-left-20">主机名：</span>
            <el-select
                    v-model="newServerBcode"
                    placeholder="请选择"
                    @change="search"
            >
                <el-option
                        v-for="(item, index) in nodeConfig"
                        v-if="item.serverHostname"
                        :key="index"
                        :label="item.serverHostname"
                        :value="item.serverBcode"
                >
                </el-option>
            </el-select>
            <span class="margin-left-20">日期：</span>
            <el-date-picker
                    v-model="selectDate"
                    type="date"
                    placeholder="选择日期"
                    :picker-options="pickerOptions"
                    @change="search"
            >
            </el-date-picker>
            <!-- 搜索主机名 -->
            <el-input  
                style="width: 200px;margin-left: 30px;" 
                placeholder="请输入要查询的主机名" 
                v-model="searchname"
             ></el-input>
             <el-button 
                type="primary" 
                style="margin-left: 7px; margin-bottom: 20px;"
                @click="searchServerBcode"
              >搜索</el-button>
            <div class="standard-node-watch">       
                <ve-line
                        v-if="flowResult &&  flowResult.rows && flowResult.rows.length"
                        class="watch-chart"
                        :data="flowResult"
                        :extend="extend"
                ></ve-line>
                <ve-line
                        v-if="cpuResult &&  cpuResult.rows && cpuResult.rows.length"
                        class="watch-chart"
                        :data="cpuResult"
                        :settings="percentSetting"
                        :extend="extend"
                ></ve-line>
                <ve-line
                        v-if="memResult && memResult .rows && memResult.rows.length"
                        :data="memResult"
                        :settings="percentSetting"
                        class="watch-chart"
                        :extend="extend"
                ></ve-line>
            </div>
            <!-- 磁盘使用 -->
            <div class="watch-real-data margin-20" v-if="memUsedResult.length">
                <el-table
                        :data="memUsedResult"
                        style="width: 100%"
                >
                    <el-table-column
                            prop="title"
                            label="磁盘使用"
                            align='center'
                    >
                    </el-table-column>
                    <el-table-column
                            prop="time"
                            label="更新时间"
                            align='center'
                    >
                    </el-table-column>
                    <!-- 磁盘使用 -->
                    <el-table-column
                            label="磁盘使用（GB）"
                            align='center'
                    >
                        <template slot-scope="scope">
                            <div :class="{ 'bonus-tips': !scope.row.used}">{{scope.row.used}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 磁盘空间利用率 -->
            <div class="watch-real-data margin-20" v-if="diskResult && diskResult.length">
                <el-table
                        :data="diskResult"
                        style="width: 100%"
                >
                    <el-table-column
                            prop="title"
                            label="磁盘空间利用率（百分比）"
                            align='center'
                    >
                    </el-table-column>
                    <el-table-column
                            prop="time"
                            label="更新时间"
                            align='center'

                    >
                    </el-table-column>
                    <!-- 磁盘空间利用率 -->
                    <el-table-column
                            label="磁盘空间利用率（百分比）"
                            align='center'
                    >
                        <template slot-scope="scope">
                            <div :class="{ 'bonus-tips': !scope.row.used_percent}">{{scope.row.used_percent}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 节点磁盘IO -->
            <div class="watch-real-data margin-20" v-if="diskioResult && diskioResult.length">
                <el-table
                        :data="diskioResult"
                        style="width: 100%"
                >
                    <el-table-column
                            prop="title"
                            label="磁盘节点磁盘IO"
                            align='center'
                    >
                    </el-table-column>
                    <el-table-column
                            prop="time"
                            label="更新时间"
                            align='center'
                    >
                    </el-table-column>
                    <!-- 磁盘百分比 -->
                    <el-table-column
                            label="节点磁盘IO（百分比）"
                            align='center'
                    >
                        <template slot-scope="scope">
                            <div :class="{ 'bonus-tips': !scope.row.io_util}">{{scope.row.io_util}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
</template>

<i18n>
    {
        "en": {
            "flowText": "单节点带宽（Mbps）",
            "cpuText": "CPU使用率（百分比）",
            "memText": "内存使用率（百分比）",
            "diskIoText": "节点磁盘IO"
        },
        "zn": {
            "flowText": "单节点带宽（Mbps）",
            "cpuText": "CPU使用率（百分比）",
            "memText": "内存使用率（百分比）",
            "diskIoText": "节点磁盘IO"
        }
    }
</i18n>


<script>
  // @ is an alias to /src
  import { mapState } from 'vuex';
  import moment from "moment";
  import { BytesToM, toFixed, BytesToG } from "@/utils/utils";
  import fetch from '@/utils/fetch';
  import { baseUrl } from '@/utils/env';

  export default {
    name: "DeviceWatch",
    data() {
      return {
        extend: {
          series:{
            itemStyle:{
              normal:{
                color:'#282557',//改变折点颜色
                lineStyle:{
                  color:'#282557', //改变折线颜色
                }
              }
            }, 
          }
        },
        newServerBcode: "",
        selectDate: moment(),
        useBandCharts: {},
        cpuResult: [],
        memResult: [],
        diskResult: [],
        memUsedResult: [], //磁盘使用
        flowResult: [],
        diskioResult: [],
        percentSetting: {
          yAxisType: ["percent"],
          yAxisName: ["百分比"]
        },
        diskioSetting: {
          yAxisType: ["percent"],
          yAxisName: ["百分比"]
        },
        pickerOptions: {
          disabledDate: time => {
            // 一个月
            return (
              time.getTime() <
              moment()
                .startOf("day")
                .valueOf() -
              24 * 1000 * 60 * 60 * 30 || time.getTime() > moment().valueOf()
            );
          }
        },
        nodeConfig: [],
        isLoading: false,
        searchname:'',//用户输入的
        serverlist:[],//被查询的列表
      };
    },
    computed: mapState({
      accountInfo: state => state.accountInfo
    }),
    methods: {
      //搜索查询主机
      searchServerBcode(){
        for( let i = 0; i < this.nodeConfig.length; i++){
          const serverlist1 = [{
            serverHostname:this.nodeConfig[i].serverHostname,
            serverBcode:this.nodeConfig[i].serverBcode,
          }]
          this.serverlist.push(serverlist1);
        }
        console.log(this.serverlist);
        let item = this.serverlist.find((item) => item[0].serverHostname == this.searchname);
        if (item) {
          //搜索到主机名存在
          let { selectDate } = this;
          this.newServerBcode = item[0].serverBcode;
          let newserverBcode = item[0].serverBcode;
          selectDate = selectDate ?
            moment(selectDate).startOf("day").valueOf() : moment().startOf("day").valueOf();
          this.getData({ bcode: newserverBcode, date: selectDate }); 
        } else {
          this.$message({
            message: "请输入正确的主机名",
            type: "error"
          })
        }
        this.searchname = '';
        this.serverlist=[];
      },

      dealFlow(data) {
        if (!data.length) {
          this.flowResult = {};
          return;
        }

        let watchDate = this.$t("watchDetail.watchDate");
        let watchUseBand = this.$t("flowText");
        // 此处为了中英文对照，所以动态处理
        let useBandCharts = {
          columns: [],
          rows: []
        };

        useBandCharts.columns = [watchDate, watchUseBand];

        data.map(item => {
          let { time, in_bps, out_bps } = item;
          // let value = isDcache ? diffBps : out_bps;
          let value = out_bps;
          let date_at = moment(new Date(time)).format("YYYY-MM-DD HH:mm:ss");

          let useBandRows = [];
          // 日期处理
          useBandRows[watchDate] = date_at;
          useBandRows[watchUseBand] = toFixed(BytesToM(value));

          useBandCharts.rows.push(useBandRows);
        });

        this.flowResult = useBandCharts;
      },
      dealCpu(data) {
        if (!data.length) {
          this.cpuResult = {};
          return;
        }
        let watchDate = this.$t("watchDetail.watchDate");
        let watchUseBand = this.$t("cpuText");
        // 此处为了中英文对照，所以动态处理
        let useBandCharts = {
          columns: [],
          rows: [],
          chartSettings: {}
        };

        useBandCharts.columns = [watchDate, watchUseBand];

        data.map(item => {
          let { time, usage_idle } = item;
          // let value = isDcache ? diffBps : out_bps;
          let value = usage_idle;
          let date_at = moment(new Date(time)).format("YYYY-MM-DD HH:mm:ss");

          let useBandRows = [];
          // 日期处理
          useBandRows[watchDate] = date_at;
          useBandRows[watchUseBand] = 1 - toFixed(value * 0.01);

          useBandCharts.rows.push(useBandRows);
        });

        this.cpuResult = useBandCharts;
      },
      dealMem(data) {
        if (!data.length) {
          this.memResult = {};
          return;
        }

        let watchDate = this.$t("watchDetail.watchDate");
        let watchUseBand = this.$t("memText");
        // 此处为了中英文对照，所以动态处理
        let useBandCharts = {
          columns: [],
          rows: []
        };

        useBandCharts.columns = [watchDate, watchUseBand];

        data.map(item => {
          let { time, used_percent } = item;
          // let value = isDcache ? diffBps : out_bps;
          let value = used_percent;
          let date_at = moment(new Date(time)).format("YYYY-MM-DD HH:mm:ss");

          let useBandRows = [];
          // 日期处理
          useBandRows[watchDate] = date_at;
          useBandRows[watchUseBand] = toFixed(value * 0.01);

          useBandCharts.rows.push(useBandRows);
        });

        this.memResult = useBandCharts;
      },

      // 节点磁盘IO
      dealDiskio(data) {
        if (!data) {
          this.diskioResult = [];
          return;
        }

        let diskioResult = [];

        for (let i in data) {
          let item = data[i][0];

          diskioResult.push({
            title: i,
            time: moment(new Date(item.time)).format("YYYY-MM-DD HH:mm:ss"),
            io_util: item.io_util ? toFixed(item.io_util) : item.io_util
          });
        }

        this.diskioResult = diskioResult;
      },

      // 磁盘使用&& 磁盘空间利用率（百分比）
      dealDisk(data) {
        if (!data) {
          this.diskResult = [];
          this.memUsedResult = [];
          return;
        }

        let diskResult = [], memUsedResult = [];

        for (let i in data) {
          let item = data[i][0];

          diskResult.push({
            title: `${i}`,
            time: moment(new Date(item.time)).format("YYYY-MM-DD HH:mm:ss"),
            used_percent: item.used_percent ? toFixed(item.used_percent) : item.used_percent
          });

          memUsedResult.push({
            title: `${i}`,
            time: moment(new Date(item.time)).format("YYYY-MM-DD HH:mm:ss"),
            used: item.used ? toFixed(BytesToG(item.used)) : item.used
          });
        }


        this.diskResult = diskResult;
        this.memUsedResult = memUsedResult;
      },
      search() {
        let { newServerBcode, selectDate } = this;
        if (!newServerBcode) return;
        selectDate = selectDate ?
          moment(selectDate).startOf("day").valueOf() : moment().startOf("day").valueOf();
        this.getData({ bcode: newServerBcode, date: selectDate });
      },
      async getData(data) {
        this.isLoading = true;
        try {
          const res = await fetch(`${baseUrl}/api/standardNode/getNodeInfoByServerCode`, data);
          let {
            cpuResult,
            memResult,
            diskResult,
            flowResult,
            diskioResult
          } = res;

          // 单节点带宽
          this.dealFlow(flowResult);
          // CPU使用率
          this.dealCpu(cpuResult);
          // 内存使用率
          this.dealMem(memResult);
          // 磁盘使用 和 磁盘空间利用率（百分比）
          this.dealDisk(diskResult)
          // 磁盘io
          this.dealDiskio(diskioResult);
        } catch (e) {
          this.$message({
            message: `查询设备状态失败：${e.message}`,
            type: 'error'
          });
        }
        this.isLoading = false;
      }
    },
    async created() {
      try {
        let res;
        if (this.accountInfo.level > 0) {
          // 查询子账号下的设备
          res = await fetch(`${baseUrl}/api/standardNode/getStandardNode`, { deviceInfo: true });
        } else {
          // 查询父账号下的设备
          res = await fetch(`${baseUrl}/api/agent/getDeviceList`);
        }
        if (res.status === 200) {
          this.nodeConfig = res.data || [];
          if (this.nodeConfig.length) {
            let server = this.nodeConfig.find(item => {
              return item.serverHostname;
            });
            this.newServerBcode = server.serverBcode;
            this.search();
          }
        } else {
          this.$message({
            message: `获取账号下的主机失败：${res.message}`,
            type: 'error'
          });
        }
      } catch (e) {
        this.$message({
          message: '获取账号下的主机失败',
          type: 'error'
        });
      }
    }
  };
</script>

<style lang="stylus" scoped>
    .box-card {
        margin: 10px 0;
    }
    .watch-real-data {
        // width: 80%;
        margin: 20px auto;
    }
    
</style>
