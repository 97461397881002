export const LANG = {
    zn: 2052,
    en: 1033
}

const testKey = '6LeagetIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const SITEKEY = {
    HIGH:  "6LdmpfYbAAAAAMrNLHyJlQaYq_tGff09hEgBRTZT",
    LOW: "6LdmpfYbAAAAADNk_JaZVd2OvMQLRVR6ndeeMpbm",
    // LOW: testKey,
}
