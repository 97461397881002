
const rules = {
  node_type: [
    { required: true, message: '请选择节点类型', trigger: 'change' },
  ],
  typeId: [
    { required: true, message: '请选择工单类型', trigger: 'change' },
  ],
  account: [
    { required: true, message: '请输入要提工单的邮箱', trigger: 'change' },
  ],
  problem_desc: [
    { required: true, message: '请填写问题描述', trigger: 'change' },
  ],
  reason: [
    { required: true, message: '请填写原因', trigger: 'change' },
  ],
  task_id: [
    { required: true, message: '请选择目标任务', trigger: 'change' },
  ],
  server_id: [
    { required: true, message: '请填写设备ID', trigger: 'change' },
  ],
  server_name: [
    { required: true, message: '请输入主机名', trigger: 'change' },
  ],
  mac_address: [
    { required: true, message: '请输入mac地址', trigger: 'change' },
    // { validator: validateMac, trigger: 'change' },
  ],
};

const validateMac = (rule, value, callback) => {
  const batchMacList = value.split(/[(\r\n)\r\n]+/);
  const strlen = function (str) {
    if (str == null) return 0;
    if (typeof str !== 'string') {
      str += '';
    }
    return str.replace(/[^\x00-\xff]/g, '01').length;
  };
  const errorList = [];
  for (let i = 0; i < batchMacList.length; i++) {
    if (!/^[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}$/.test(batchMacList[i]) || !batchMacList[i] || strlen(batchMacList[i]) !== 17) {
      errorList.push(i + 1);
    }
  }
  if (errorList.length != 0) {
    callback(new Error(`请在第${errorList}行输入正确的mac地址`));
  } else {
    return true;
  }
};

const nodeTypeList = [
  {
    label: '轻节点',
    value: '2',
    disabled: true,
  },
  {
    label: '标准节点',
    value: '1',
  },
];

export {
  rules,
  nodeTypeList,
};
