<template>
    <!--  该功能只适用于子账号  -->
    <div v-if="isFather" style="padding: 20px; text-align: center">无权限</div>
    <div v-else class="home contact">
        <Tips
                title="注意事项"
                :tips="$t('tips')"
                class="withdrawal-tips"
        />
        <Type1Verify :info="info" />
        <Type2Verify :user_name="user_name" />
        <Type3Verify :user_name="user_name" />
    </div>
</template>

<i18n>
    {
    "zn": {
    "tips": [
    ]
    }
    }
</i18n>


<script>
  // @ is an alias to /src
  import { mapState } from "vuex";
  import AccountSetLayout from "@/components/AccountSet/AccountSetLayout.vue";
  import BasiceLayout from "@/components/Common/BasicLayout.vue";
  import { Message } from "element-ui";
  import ImageCode from "@/components/ImageCode.vue";
  import SendEmailCode from "@/components/SendEmailCode.vue";
  import VueRecaptcha from "vue-recaptcha";
  import Tips from "@/components/Common/Tips.vue";
  import SendVerifyCode from "@/components/Common/SendVerifyCode.vue";
  import Type1Verify from "@/components/AccountSet/Type1Verify.vue";
  import Type2Verify from "@/components/AccountSet/Type2Verify.vue";
  import Type3Verify from "@/components/AccountSet/Type3Verify.vue";
  import fetch from '../../utils/fetch';
  import { baseUrl } from '../../utils/env';

  export default {
    name: "Contact",
    components: {
      BasiceLayout,
      AccountSetLayout,
      ImageCode,
      SendEmailCode,
      VueRecaptcha,
      Tips,
      SendVerifyCode,
      Type1Verify,
      Type2Verify,
      Type3Verify
    },
    computed: mapState({
      isFather: state => !state.accountInfo.level
    }),
    data() {
      return {
        contact: "",
        user_name: "",
        tel: "",
        type: "EDIT",
        verifyCode: "",
        noIcon: false,
        nameVerStatus: '',
        IDCard: '',
        info: ''
      };
    },
    created() {
      this.updateContact();
    },
    methods: {
      getContact() {
        return fetch(`${baseUrl}/api/contact/getContact`);
      },
      setContact(data) {
        return fetch(`${baseUrl}/api/contact/setContact`, data, 'POST');
      },
      verifyTel() {
        let { user_name, contact, tel, verifyCode } = this;

        if (!user_name || !contact || !tel || !verifyCode) {
          return Message({
            message: "请完整填写信息",
            type: "error",
          });
        } else {
          this.setContact({
            contact,
            user_name,
            tel,
            verifyCode,
            type: "TEL"
          }).then((res) => {
            if (res.code === 200) {
              this.type === "WaitEdit";
              Message({
                message: "保存成功",
                type: "success",
              });
              this.updateContact();
            } else {
              Message({
                message: res.message || "网络失败",
                type: "error",
              });
            }
          });
        }
      },
      clickCommitContact() {
        let { user_name, contact, tel, verifyCode, IDCard } = this;

        if (!user_name || !contact || !tel || !verifyCode) {
          return Message({
            message: "请完整填写信息",
            type: "error",
          });
        } else {
          this.setContact({
            contact,
            user_name,
            tel,
            verifyCode,
            IDCard
          }).then((res) => {
            if (res.code === 200) {
              this.type === "WaitEdit";
              Message({
                message: "保存成功",
                type: "success",
              });
              this.updateContact();
            } else {
              Message({
                message: res.message || "网络失败",
                type: "error",
              });
            }
          });
        }
      },
      // 获取最新联系方式
      updateContact() {
        this.getContact().then((res) => {
          if (res.data && res.data.contact) {
            let data = res.data;
            this.type = "WaitEdit";
            this.contact = data.contact;
            this.user_name = data.user_name;
            this.tel = data.tel;
            this.telVerStatus = data.telVerStatus;
            this.nameVerStatus = data.nameVerStatus;
            this.IDVerStatus = data.IDVerStatus;
            this.IDCard = data.IDCard;

            this.info = res.data;

            if (!data.telVerStatus) {
              Message({
                message: "根据实名认证要求，请完成手机号绑定。",
                type: "error",
              });
            }
          } else {
            Message({
              message: "根据实名认证要求，请完成手机号绑定。",
              type: "error",
            });
            this.type = "EDIT";
          }
        });
      },
      verifyCodeTip(error) {
        if (error && error.message) {
          Message({
            message: error.message,
            type: "error",
          });
        }
      },
    },
  };
</script>

<style lang="stylus">
    .contact-commit-wrap {
        text-align: center;
        margin-bottom: 50px;
    }

    .contact .el-input {
        max-width: 300px;
    }

    .account-wrap .left-item {
        display: flex;
        justify-content: flex-start;
        width: 100px;
    }

    .right-text{
        min-width: 100px
    }

    .right-item.margin-left-20 {
        min-width: 250px;
    }
    .is-mobile{
        .right-text{
            margin-left: 20px;
            font-weight: 400;
            font-size: 14px;
        }
    }
    .email_wrap{
        width: 200px;
    }
</style>

