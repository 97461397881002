<template>
    <el-container>
        <el-aside width="200px">
            <div class="menu-name">我的钱包</div>
            <el-menu router
                     :default-active="$route.path"
                     class="el-menu"
                     background-color="rgb(17,17,53)"
                     text-color="rgb(152, 154, 162)"
                     active-text-color="#fff"
            >
                <el-menu-item index="/wallet/paymentManage">收款方式</el-menu-item>
                <el-menu-item index="/wallet/contact">身份验证</el-menu-item>
            </el-menu>
        </el-aside>
        <el-main style="background-color: #f6f6f7">
            <router-view></router-view>
        </el-main>
    </el-container>
</template>

<script>
  export default {
    name: 'WalletLayout'
  };
</script>

<style scoped>
    .el-aside {
        background-color: rgb(17,17,53);
    }
    .menu-name {
        padding-left: 25px;
        text-align: left;
        height: 40px;
        font-size: 16px;
        line-height: 40px;
        opacity: 0.5;
        color: #fff;
        font-weight: bolder;
    }
</style>
