/** 高级实名验证 */
<template>
  <div class="">
    <!-- 手机验证 -->
    <BasiceLayout
      title="高级实名验证"
      class="account-wrap margin-top-20"
    >
      <!-- 姓名 -->
      <div class=" item-wraps display-flex-center margin-20">
        <div class="left-item margin-right-20">姓名</div>
        <div class="right-item margin-left-20">
          <div class="right-text">{{user_name ? user_name : "请在手机验证中验证"}}</div>
        </div>
      </div>
      <!-- 手机 -->
      <div class="item-wraps display-flex-center margin-20">
        <div class="left-item margin-right-20">手机号</div>
        <div class="right-item margin-left-20">
          <div class="right-text">
            {{tel ? tel : "请在手机验证中验证"}}
          </div>
        </div>
      </div>
      <!-- 身份证 -->
      <div class="item-wraps display-flex-center margin-20">
        <div class="left-item margin-right-20">身份证号</div>
        <div class="right-item margin-left-20">
          <div
            v-if="type !== 'EDIT'"
            class="right-text"
          >
            {{IDCard}}
          </div>
          <el-input
            v-if="type === 'EDIT'"
            v-model="IDCard"
            placeholder="请填写真实的身份证信息"
          ></el-input>
        </div>
      </div>
      <!-- 验证状态 -->
      <div class="item-wraps display-flex-center margin-20">
        <div class="left-item margin-right-20">{{ !Type3VStatus || type === 'EDIT' ? "手机验证码" : "高级身份验证"}}</div>
        <div class="right-item margin-left-20">
          <SendVerifyCode
            v-if="!Type3VStatus || type === 'EDIT'"
            :iconType="noIcon"
            type="text"
            icon-type="emailCode"
            v-model="verifyCode"
            :tel="tel"
            @verifyCodeTip="verifyCodeTip"
            placeValue="发送手机验证码"
          />
          <div
            v-else
            class="right-text"
          >
            <el-tag type="success">已验证</el-tag>
          </div>
        </div>
      </div>
      <!-- 身份证图片 -->
      <div class=" item-wraps display-flex-center margin-20" v-if="!Type3VStatus || type === 'EDIT'">
        <!-- 人像面 -->
        <div class="margin-20">
          <div class="margin-20 display-flex-center">人像身份证照</div>
          <el-upload
            class="avatar-uploader"
            action="/api/upload/uploadImage"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAUpload"
            :headers="headers"
          >
            <img
              v-if="icA"
              :src="icA"
              class="avatar"
            >
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
        </div>

        <!-- 国旗面 -->
        <div class="margin-20">
          <div class="margin-20 display-flex-center">国徽面</div>
          <el-upload
            class="avatar-uploader"
            action="/api/upload/uploadImage"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeBUpload"
          >
            <img
              v-if="icB"
              :src="icB"
              class="avatar"
            >
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
        </div>

      </div>

      <!-- 确定 -->
      <div class="contact-commit-wrap">
        <div
          v-if="type === 'EDIT'"
          class="button margin-top-20"
          @click="clickCommitContact"
        >确定</div>
        <div
          v-if="type !== 'EDIT'"
          class="button margin-top-20"
          @click="type = 'EDIT'"
        >修改</div>
      </div>
    </BasiceLayout>
  </div>
</template>

<script>
import AccountSetLayout from "@/components/AccountSet/AccountSetLayout.vue";
import BasiceLayout from "@/components/Common/BasicLayout.vue";
import { Message, Upload } from "element-ui";
import ImageCode from "@/components/ImageCode.vue";
import SendEmailCode from "@/components/SendEmailCode.vue";
import VueRecaptcha from "vue-recaptcha";
import Tips from "@/components/Common/Tips.vue";
import SendVerifyCode from "@/components/Common/SendVerifyCode.vue";
import fetch from '../../utils/fetch';
import { baseUrl } from '../../utils/env';

export default {
  name: "AccountLayout",
  components: {
    BasiceLayout,
    AccountSetLayout,
    ImageCode,
    SendEmailCode,
    VueRecaptcha,
    Tips,
    SendVerifyCode,
    Upload,
  },
  data() {
    return {
      contact: "",
      user_name: "",
      tel: "",
      type: "EDIT",
      verifyCode: "",
      noIcon: false,
      IDCard: "",
      Type3VStatus: "",
      imageUrl: "",
      icA: "",
      imageA: "",
      icB: "",
      imageData: {},
      imageAName: "",
      headers: {
        enctype: "multipart/form-data"
      }
    };
  },
  methods: {
    getContact() {
      return fetch(`${baseUrl}/api/contact/getContact`);
    },
    setContact(data) {
      return fetch(`${baseUrl}/api/contact/setContact`, data, 'POST');
    },
    handleAvatarSuccess(res, file) {
      //声明js的文件流
      var reader = new FileReader();
      if (file) {
        //通过文件流将文件转换成Base64字符串
        reader.readAsDataURL(file);
        //转换成功后
        reader.onloadend = function () {
          //将转换结果赋值给img标签
          //输出结果
          // console.log(reader.result);
          this.icA = reader.result;
        };
      }

      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAUpload(file) {
      console.log(file)
      this.imageA = file;

      let that = this;

      var reader = new FileReader();
      if (file) {
        //通过文件流将文件转换成Base64字符串
        reader.readAsDataURL(file);
        //转换成功后
        reader.onloadend = function () {
          //将转换结果赋值给img标签
          //输出结果
          that.icA = reader.result;
        };
      }
    },
    beforeBUpload(file) {
      let that = this;

      var reader = new FileReader();
      if (file) {
        //通过文件流将文件转换成Base64字符串
        reader.readAsDataURL(file);
        //转换成功后
        reader.onloadend = function () {
          //将转换结果赋值给img标签
          //输出结果
          that.icB = reader.result;
        };
      }
    },
    clickCommitContact() {
      let { user_name, contact, tel, verifyCode, IDCard, icA, icB, imageA, imageAName } = this;

      if (!user_name || !contact || !tel || !verifyCode || !icA || !icB) {
        return Message({
          message: "请完整填写信息",
          type: "error",
        });
      } else {
        this.setContact({
          contact,
          user_name,
          tel,
          verifyCode,
          IDCard,
          type: 3,
          icA,
          icB,
          imageA,
          imageAName
        }).then((res) => {
          if (res.code === 200) {
            this.type === "WaitEdit";
            Message({
              message: "保存成功",
              type: "success",
            });
            this.updateContact();
          } else {
            let message = res.data && res.data.verifyMsg ? res.data.verifyMsg : res.details;
            Message({
              message: message ||  "网络失败",
              type: "error",
            });
          }
        });
      }
    },
    // 获取最新联系方式
    updateContact() {
      this.getContact().then((res) => {
        let data = res.data;
        this.contact = data.contact;
        this.user_name = data.user_name;
        this.tel = data.tel;
        this.Type3VStatus = data.Type3VStatus;
        this.IDCard = data.IDCard;

        if (res.data && res.data.Type3VStatus) {
          this.type = "WaitEdit";
        } else {
          this.type = "EDIT";
        }
      });
    },
    verifyCodeTip(error) {
      if (error && error.message) {
        Message({
          message: error.message,
          type: "error",
        });
      }
    },
  },
  created() {
    this.updateContact();
  },
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus">
.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

i.el-icon-plus.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar{
  width: 178px;
  height: 178px;
}
</style>

