<template>
    <div>
        <el-card shadow="never" class="box-card">
            <h4>工单记录</h4>
            <div style="margin-top: 20px;">
                <el-table :data="orderList" style="width: 100%; margin-bottom: 10px;">
                    <el-table-column prop="Account" label="工单账号" align="center">
                    </el-table-column>
                    <el-table-column prop="ProblemDesc" align="center" label="问题描述">
                    </el-table-column>
                    <el-table-column prop="HostId" align="center" label="serverID">
                        <template slot-scope="scope">
                            <p v-if="scope.row.HostId">{{scope.row.HostId}}</p>
                            <p v-else></p> 
                        </template>
                    </el-table-column>
                    <el-table-column prop="HostName" align="center" label="主机名">
                    </el-table-column>
                    <el-table-column prop="TaskId" align="center" label="所选任务">
                        <template slot-scope="scope">
                            <p v-if="scope.row.TaskId">{{scope.row.TaskId}}</p>
                            <p v-else></p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Reason" align="center" label="切换或下线原因">
                    </el-table-column>
                    <el-table-column prop="Status" align="center" label="工单状态">
                        <template slot-scope="scope">
                            <p v-if="scope.row.Status == 1">待处理</p>
                            <p v-if="scope.row.Status == 2">处理中</p>
                            <p v-if="scope.row.Status == 3" style="color: red;cursor: pointer;" @click="setAddition(scope.row)">待补充</p>
                            <p v-if="scope.row.Status == 4" style="color: #67C33A; cursor: pointer;" @click="showDoneResult(scope.row)">已完成</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="UserName" align="center" label="提交账号">
                    </el-table-column>
                    <el-table-column prop="CreateTime" align="center" label="提交时间">
                    </el-table-column>
                </el-table>
                <div style="text-align: right">
                    <el-pagination background layout="prev, pager, next" :page-size="PAGE_SIZE" :total="total"
                        :current-page="currentPage" @current-change="onQuery">
                    </el-pagination>
                </div>
            </div>
        </el-card>
        <el-dialog title="补充资料" :visible.sync="additionVisible" style="width: 62%;margin: 0 auto;">
            <el-form :model="infoFormData" ref="infoForm" label-position="left" label-width="120px" :rules="infoRules">
                <el-form-item label="待补充内容">
                    <p style="color: red;">{{additionInfo}}</p>
                </el-form-item>
                <el-form-item label="补充描述" prop="supplement_desc">
                    <el-input v-model="infoFormData.supplement_desc" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="故障截图" prop="image_urls">
                    <el-upload ref="uploadRef" class="upload-demo" :action="uploadUrl" name="image"
                        :file-list="fileList" :data="uploadData" :headers="headers" accept=".jpg,.jpeg,.png" :limit="4"
                        :on-success="successUpload" :on-exceed="handleExceed" drag multiple show-file-list>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="additionVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitAddition">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 完成结果 -->
        <el-dialog title="处理结果" :visible.sync="resultVisible" style="width: 62%;margin: 0 auto;">
           <p>{{doneResult}}</p>
        </el-dialog>
    </div>
</template>

<script>
    import fetch from '../../utils/fetch';
    import { baseUrl } from '../../utils/env';

    // const uploadUrl = "https://api-test.applet.bonuscloud.work/crm/uploadImage";
    const uploadUrl = "https://api.applet.bonuscloud.work/crm/uploadImage";

    export default {
        data() {
            return {
                orderList: [],
                total: 0,
                PAGE_SIZE: 20,
                currentPage: 1,
                infoFormData: {
                    work_order_id: '',
                    supplement_desc: '',
                    image_urls: '',
                },
                infoRules: {
                    supplement_desc: [
                        { required: true, message: '请输入备注', trigger: 'blur' }
                    ]
                },
                additionVisible: false,
                resultVisible: false,
                additionInfo: '',
                filePathsList: [],
                fileList: [],
                uploadUrl,
                headers: {
                    "token": "r0lGF4aEgAp+U16oigtrppFJE5brEQQplqBwG9O1dL4="
                },
                uploadData: {
                    type: 1,
                },
                doneResult: '',
            }
        },
        methods: {
            async getOrderList() {
                const params = {
                    page: this.currentPage,
                    pagesize: this.PAGE_SIZE
                }
                const res = await fetch(baseUrl + '/api/order/getOrderRecordList', params);
                this.orderList = res.data.data;
                this.total = res.data.total;
            },
            onQuery(val) {
                this.currentPage = val;
                this.getOrderList();
            },
            showDoneResult(row) {
                this.resultVisible = true;
                this.doneResult = row.Result;
            },
            setAddition(row) {
                this.additionVisible = true;
                this.additionInfo = row.SupplementDesc;
                this.infoFormData = {
                    work_order_id: row.Id,
                    supplement_desc: '',
                    image_urls: '',
                }
                this.$refs.uploadRef.clearFiles();
            },
            submitAddition() {
                this.$refs.infoForm.validate(async (valid) => {
                    if(valid) {
                        let list = [];
                        this.filePathsList.forEach(item => {
                            const { ImageUrl } = item.response.data;
                            list.push(ImageUrl);
                        })
                        this.infoFormData.image_urls = list.join(',');
                        const res = await fetch(baseUrl + '/api/order/submitAddition', this.infoFormData, 'POST');
                        if(res.code == 0) {
                            this.additionVisible = false;
                            this.$message.success('补充成功，等待处理中');
                            this.getOrderList();
                        } else {
                            this.$message.error('补充失败，请重试');
                        }
                    }
                })
            },
            successUpload(response, file, fileList) {
                if (response.code == 0) {
                    this.filePathsList = fileList;
                }
            },
            handleExceed() {
                this.$message.warning(`限制选择 4 个文件`);
            }
        },
        created() {
            this.getOrderList()
        }
    }

</script>

<style scoped>
    /deep/ .el-form--label-left .el-form-item__label {
        text-align: right;
    }
</style>