let isZnWebsite;
if (window.location.href.includes('language=zn')) {  
  isZnWebsite =  true;
} else if (window.location.href.includes('language=en')) {  
  isZnWebsite =  false;
} else if (window.location.host.includes('console.bonuscloud.io')) {
  isZnWebsite =  false;
} else {
  isZnWebsite =  true;
}

export {
  isZnWebsite
};
