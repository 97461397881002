<template>
  <div v-if="!isFather" style="padding: 20px; text-align: center">无权限</div>
  <div v-else>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>资源管理</el-breadcrumb-item>
      <el-breadcrumb-item>工单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 创建工单 -->
    <CreateOrder @clickBrotherBtn="triggerBrotherMethods"></CreateOrder>
    <!-- 工单记录 -->
    <OrderList ref="listRef"></OrderList>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import fetch from '../../utils/fetch';
  import { baseUrl } from '../../utils/env';
  import CreateOrder from '@/components/Order/CreateOrder.vue';
  import OrderList from '@/components/Order/OrderList.vue';

  export default {
    name: 'AccountManage',
    computed: mapState({
      isFather: state => !state.accountInfo.level
    }),
    components: {
      CreateOrder,
      OrderList
    },
    data() {
      return {
  
      };
    },
    methods: {
      triggerBrotherMethods() {
        this.$refs.listRef.getOrderList()
      }
    }
  };
</script>

<style scoped>
  .box-card {
    margin: 10px 0;
  }

  .create-form-wrapper {
    width: 30%;
    margin: 10px auto 0;
  }

  /deep/ .el-upload-dragger {
    width: 278px;
  }
</style>