<template>
  <div class="tips-layout">
      <div class="tips-wrap">
        <div v-for="item in tips" class="tips">
          <div v-if="!item.includes('<special>')">{{item}}</div>
          <div v-else>
            <span v-for="text, index in item.split('<special>')">
              <span v-bind:class="{ isSpecial: (index %2 !== 0) }">{{text}}</span>
            </span>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'BasicLayout',
  props: {
    title: '',
    tips: ''
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="stylus">
.tips-wrap {
  text-align: left;
  font-size: 14px;
  width: 80%;
  padding: 8px 14px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  opacity: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  background-color: #f4f4f5;
  color: #909399;
  line-height: 30px;
}

.isSpecial{
  color: #f56c6c
}
</style>
