<template>
    <!--  该功能只适用于父账号  -->
    <div v-if="!isFather" style="padding: 20px; text-align: center">无权限</div>
    <div v-else>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>资源管理</el-breadcrumb-item>
            <el-breadcrumb-item>子账户管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card shadow="never" class="box-card">
            <h4>子账户创建</h4>
            <div class="create-form-wrapper">
                <el-form ref="createForm" label-position="left" label-width="120px" :model="createFormData" :rules="rules">
                    <el-form-item label="子账号邮箱" prop="email">
                        <el-input placeholder="输入要创建账号的邮箱" v-model="createFormData.email"></el-input>
                    </el-form-item>
                    <el-form-item label="子账号密码" prop="password">
                        <el-input placeholder="输入子账号密码（子账号所有者可修改）"
                                  v-model="createFormData.password"
                                  show-password
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="子账号备注" prop="remark">
                        <el-input placeholder="用于账号标识快速查询（子账号不可见）"
                                  v-model="createFormData.remark"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-loading="isCreating" @click="createAccount">创建</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" class="box-card">
            <h4>子账户管理</h4>
            <el-form :inline="true" :model="condition" style="margin: 10px 0">
                <el-form-item label="搜索">
                    <el-select v-model="condition.queryType">
                        <el-option label="邮箱" value="EMAIL"></el-option>
                        <el-option label="备注" value="REMARK"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="condition.queryStr" placeholder="输入要搜索的信息"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>
            <div v-loading="loading">
                <p style="margin: 10px 0;">子账号总数：{{ total }}</p>
                <el-table
                        :data="tableData"
                        style="width: 100%; margin-bottom: 10px;">
                    <el-table-column
                            prop="email"
                            label="邮箱"
                            align="center"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            prop="remark"
                            align="center"
                            label="备注">
                    </el-table-column>
                    <el-table-column
                            prop="tel"
                            align="center"
                            label="手机号">
                    </el-table-column>
                    <el-table-column
                            prop="contact"
                            align="center"
                            label="微信/QQ">
                    </el-table-column>
                    <el-table-column
                            prop="deviceCount"
                            align="center"
                            label="设备数量">
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" style="margin-top: 5px" @click="handleModifySonAccountPwd(scope.$index, scope.row)">修改密码</el-button>
                            <el-button size="mini" style="margin-top: 5px" @click="handleEdit(scope.$index, scope.row)">修改信息</el-button>
                            <el-button size="mini" style="margin-top: 5px" @click="priceManage(scope.$index, scope.row)">单价管理</el-button>
                            <el-button size="mini" style="margin-top: 5px" @click="viewMoneyAccount(scope.$index, scope.row)">查看收款信息</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right">
                    <el-pagination
                            background
                            layout="prev, pager, next"
                            :page-size="PAGE_SIZE"
                            :total="total"
                            :current-page="currentPage"
                            @current-change="onQuery"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-card>
        <el-dialog title="单价管理" :visible.sync="priceFormVisible">
            <el-dialog
                    width="30%"
                    title="提示"
                    :visible.sync="confirmDialogVisible"
                    append-to-body>
                <p>确定保存单价？</p>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="confirmDialogVisible = false">取 消</el-button>
                    <el-button v-loading="isSavingPrice" type="primary" @click="savePrice">确 定</el-button>
                </div>
            </el-dialog>
            <el-form :model="priceFormData" ref="priceForm" label-position="left" label-width="120px" :rules="priceRules">
                <el-form-item label="电信" prop="dxPrice">
                    <el-input v-model="priceFormData.dxPrice" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联通" prop="ltPrice">
                    <el-input v-model="priceFormData.ltPrice" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="移动" prop="ydPrice">
                    <el-input v-model="priceFormData.ydPrice" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="priceFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmPrice">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="修改信息" :visible.sync="infoFormVisible">
            <el-form :model="infoFormData" ref="infoForm" label-position="left" label-width="120px" :rules="infoRules">
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="infoFormData.remark" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="tel">
                    <el-input v-model="infoFormData.tel" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="微信/QQ" prop="contact">
                    <el-input v-model="infoFormData.contact" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="infoFormVisible = false">取 消</el-button>
                <el-button v-loading="isSavingInfo" type="primary" @click="saveInfo">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="修改子账号密码" :visible.sync="pwdFormVisible">
            <el-form :model="pwdFormData" ref="pwdForm" label-position="left" label-width="120px" :rules="pwdRules">
                <el-form-item label="新密码" prop="password">
                    <el-input v-model="pwdFormData.password" show-password autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="rePassword">
                    <el-input v-model="pwdFormData.rePassword" show-password autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="pwdFormVisible = false">取 消</el-button>
                <el-button v-loading="isChangingPwd" type="primary" @click="modifySonAccountPwd">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="查看收款信息" :visible.sync="moneyFormVisible">
            <el-form :model="moneyAccountData" label-position="left" label-width="100px">
                <h4 style="margin-bottom: 10px">银行卡</h4>
                <el-form-item label="户名">
                    <el-input v-model="moneyAccountData.bankAccountName" disabled></el-input>
                </el-form-item>
                <el-form-item label="开户行">
                    <el-input v-model="moneyAccountData.bankAddress" disabled></el-input>
                </el-form-item>
                <el-form-item label="账号">
                    <el-input v-model="moneyAccountData.bankAccount" disabled></el-input>
                </el-form-item>
                <h4 style="margin-bottom: 10px">支付宝</h4>
                <el-form-item label="姓名">
                    <el-input v-model="moneyAccountData.ZFBName" disabled></el-input>
                </el-form-item>
                <el-form-item label="账号">
                    <el-input v-model="moneyAccountData.ZFBAccount" disabled></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="moneyFormVisible = false">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  import { mapState } from 'vuex';
  import fetch from '../../utils/fetch';
  import { baseUrl } from '../../utils/env';
  const PAGE_SIZE = 20;
  export default {
    name: 'AccountManage',
    computed: mapState({
      isFather: state => !state.accountInfo.level
    }),
    data() {
      return {
        createFormData: {
          email: '',
          password: '',
          remark: ''
        },
        condition: {
          queryType: 'EMAIL',
          queryStr: ''
        },
        rules: {
          email: [
            { required: true, message: '请输入账号邮箱', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入账号密码', trigger: 'blur' }
          ],
          remark: [
            { required: true, message: '请输入备注', trigger: 'blur' },
            { max: 100, message: '长度请不要超过100个字符', trigger: 'blur' }
          ]
        },
        isCreating: false,
        loading: false,
        tableData: [],
        total: 0,
        PAGE_SIZE,
        currentPage: 1,
        priceFormVisible: false,
        confirmDialogVisible: false,
        priceFormData: {},
        priceRules: {
          dxPrice: [
            { required: true, message: '请输入单价', trigger: 'blur' }
          ],
          ltPrice: [
            { required: true, message: '请输入单价', trigger: 'blur' }
          ],
          ydPrice: [
            { required: true, message: '请输入单价', trigger: 'blur' }
          ]
        },
        currentAccount: {},
        isSavingPrice: false,
        infoFormData: {},
        infoRules: {
          remark: [
            { required: true, message: '请输入备注', trigger: 'blur' }
          ],
          tel: [
            { required: true, message: '请输入手机号', trigger: 'blur' }
          ],
          contact: [
            { required: true, message: '请输入微信/QQ', trigger: 'blur' }
          ]
        },
        infoFormVisible: false,
        isSavingInfo: false,
        pwdFormVisible: false,
        pwdRules: {
          password: [
            { required: true, message: '请输入新密码', trigger: 'blur' }
          ],
          rePassword: [
            { required: true, message: '请再次输入新密码', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (value !== this.pwdFormData.password) {
                  callback('请确保两次输入的密码一致')
                } else {
                  callback();
                }
              },
              trigger: 'blur'
            }
          ],
        },
        pwdFormData: {},
        isChangingPwd: false,
        moneyFormVisible: false,
        moneyAccountData: {}
      };
    },
    created() {
      if (this.isFather) {
        this.onQuery(1);
      }
    },
    methods: {
      // 创建子账号
      createAccount() {
        this.$refs['createForm'].validate(async (valid) => {
          if (!valid) return;
          this.isCreating = true;
          try {
            const res = await fetch(
              `${baseUrl}/api/agent/createAccount`,
              this.createFormData,
              'POST'
            );
            if (res.code === 200) {
              this.$message({
                message: '创建子账号成功',
                type: 'success'
              });
              this.$refs['createForm'].resetFields({
                email: '',
                password: '',
                remark: ''
              });
            } else {
              this.$message({
                message: `创建失败：${res.message}`,
                type: 'error'
              });
            }
          } catch (e) {
            this.$message({
              message: `查询出错：${e.message}`
            });
          }
          this.isCreating = false;
        });
      },
      onSubmit() {
        this.onQuery(1);
      },
      // 查询子账号
      async onQuery(pageNum) {
        this.loading = true;
        const res = await fetch(`${baseUrl}/api/agent/getAccountList`, {
          pageNum,
          pageSize: PAGE_SIZE,
          ...this.condition
        });
        if (res.code === 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentPage = pageNum;
        } else {
          this.$message({
            message: `查询失败：${res.message}`,
            type: 'error'
          });
        }
        this.loading = false;
      },
      handleEdit(index, row) {
        this.currentAccount = row;
        this.infoFormData = {
          remark: row.remark,
          tel: row.tel,
          contact: row.contact
        };
        this.infoFormVisible = true;
      },
      handleModifySonAccountPwd(index, row) {
        this.currentAccount = row;
        this.pwdFormData = {};
        this.pwdFormVisible = true;
      },
      priceManage(index, row) {
        this.currentAccount = row;
        this.priceFormData = {
          dxPrice: row.dxPrice,
          ltPrice: row.ltPrice,
          ydPrice: row.ydPrice
        };
        this.priceFormVisible = true;
      },
      viewMoneyAccount(index, row) {
        this.currentAccount = row;
        this.moneyAccountData = {
          bankAccount: row.bankAccount,
          bankAccountName: row.bankAccountName,
          bankAddress: row.bankAddress,
          ZFBName: row.ZFBName,
          ZFBAccount: row.ZFBAccount
        };
        this.moneyFormVisible = true;
      },
      confirmPrice() {
        this.$refs['priceForm'].validate(isValid => {
          if (!isValid) return;
          this.confirmDialogVisible = true;
        });
      },
      async savePrice() {
        if (this.isSavingPrice) return;
        this.isSavingPrice = true;
        try {
          const res = await fetch(`${baseUrl}/api/agent/savePrice`, {
            ...this.priceFormData,
            accountId: this.currentAccount.id
          }, 'POST');
          if (res.code === 200) {
            this.$message({
              message: '保存单价成功',
              type: 'success'
            });
            this.confirmDialogVisible = false;
            this.priceFormVisible = false;
            this.onQuery(this.currentPage);
          } else {
            this.$message({
              message: `保存单价失败：${res.message}`,
              type: 'error'
            });
          }
        } catch (e) {
          this.$message({
            message: `保存单价失败：${e.message}`,
            type: 'error'
          });
        }
        this.isSavingPrice = false;
      },
      // 修改信息
      saveInfo() {
        this.$refs['infoForm'].validate(async (isValid) => {
          if (!isValid) return;
          if(this.isSavingInfo) return;
          this.isSavingInfo = true;
          try {
            const res = await fetch(`${baseUrl}/api/agent/saveAccountInfo`, {
              ...this.infoFormData,
              accountId: this.currentAccount.id
            }, 'POST');
            if (res.code === 200) {
              this.$message({
                message: '保存信息成功',
                type: 'success'
              });
              this.infoFormVisible = false;
              this.onQuery(this.currentPage);
            } else {
              this.$message({
                message: `保存单价失败：${res.message}`,
                type: 'error'
              });
            }
          } catch (e) {
            this.$message({
              message: `保存信息失败：${e.message}`,
              type: 'error'
            });
          }
          this.isSavingInfo = false;
        });
      },
      modifySonAccountPwd() {
        this.$refs['pwdForm'].validate(async (isValid) => {
          if (!isValid) return;
          if(this.isChangingPwd) return;
          this.isChangingPwd = true;
          try {
            const res = await fetch(`${baseUrl}/api/agent/modifySonAccountInfoPwd`, {
              ...this.pwdFormData,
              accountId: this.currentAccount.id
            }, 'POST');
            if (res.code === 200) {
              this.$message({
                message: '修改密码成功',
                type: 'success'
              });
              this.pwdFormVisible = false;
              this.onQuery(this.currentPage);
            } else {
              this.$message({
                message: `修改密码失败：${res.message}`,
                type: 'error'
              });
            }
          } catch (e) {
            this.$message({
              message: `修改密码失败：${e.message}`,
              type: 'error'
            });
          }
          this.isChangingPwd = false;
        });
      }
    }
  };
</script>

<style scoped>
    .box-card {
        margin: 10px 0;
    }
    .create-form-wrapper {
        width: 30%;
        margin: 10px auto 0;
    }
</style>
