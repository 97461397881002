<template>
    <!--  该功能只适用于子账号  -->
    <div v-if="isFather" style="padding: 20px; text-align: center">无权限</div>
    <div v-else class="home hardlist-home">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px">
            <el-breadcrumb-item>资源管理</el-breadcrumb-item>
            <el-breadcrumb-item>矿机管理</el-breadcrumb-item>
            <el-breadcrumb-item>设备提交</el-breadcrumb-item>
        </el-breadcrumb>
        <Tips
            title="标准节点提交注意事项"
            :tips="$t('tips')"
            class="withdrawal-tips"
        />
        <Node></Node>
    </div>
</template>

<i18n>
    {
    "zn": {
    "tips": [
        ]
        }
        }
</i18n>


<script>
  // @ is an alias to /src
  import Tips from "@/components/Common/Tips.vue";
  import Node from "@/components/Hardware/Node.vue";
  import { mapState } from "vuex";

  export default {
    name: "CommitDevice",
    components: {
      Tips,
      Node
    },
    computed: mapState({
      isFather: state => !state.accountInfo.level
    }),
  };
</script>

<style lang="stylus"></style>
