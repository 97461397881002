<template>
    <el-container>
        <el-aside width="200px">
            <div class="menu-name">资源管理</div>
            <el-menu router
                     :default-active="$route.path"
                     class="el-menu"
                     background-color="rgb(17,17,53)"
                     text-color="rgb(152, 154, 162)"
                     active-text-color="#fff"
            >
                <el-menu-item index="/resource/index">首页</el-menu-item>
                <el-menu-item v-if="isFather" index="/resource/accountManage">子账户管理</el-menu-item>
                <el-submenu index="3">
                    <template slot="title">矿机管理</template>
                    <el-menu-item v-if="isFather" index="/resource/auditDevice">待提交设备审核</el-menu-item>
                    <el-menu-item v-if="isFather" index="/resource/deviceDataView">矿机数据总览</el-menu-item>
                    <el-menu-item v-if="isFather" index="/resource/deviceList">设备列表</el-menu-item>
                    <el-menu-item index="/resource/deviceWatch">单设备监控</el-menu-item>
                    <el-menu-item v-if="!isFather" index="/resource/deviceListForChild">设备状态收益</el-menu-item>
                    <el-menu-item v-if="!isFather" index="/resource/commitDevice">设备提交</el-menu-item>
                    <el-menu-item v-if="!isFather" index="/resource/dialConfig">拨号配置管理</el-menu-item>
                </el-submenu>
                <el-menu-item index="/resource/dialStatus">软路由-拨号状态</el-menu-item>
                <el-menu-item v-if="isFather" index="/resource/orderManagement">工单管理</el-menu-item>
            </el-menu>
        </el-aside>
        <el-main style="background-color: #f6f6f7">
            <router-view></router-view>
        </el-main>
    </el-container>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: 'ResourceLayout',
    computed: mapState({
      isFather: state => !state.accountInfo.level
    }),
  };
</script>

<style scoped>
    .el-aside {
        background-color: rgb(17,17,53);
    }
    .menu-name {
        padding-left: 25px;
        text-align: left;
        height: 40px;
        font-size: 16px;
        line-height: 40px;
        opacity: 0.5;
        color: #fff;
        font-weight: bolder;
    }

    .logo-wrapper {
        /* display: flex; */
        /* display: block; */
        float: left;
        height: 60px;
        line-height: 60px;
        margin: 0 40px;
        font-weight: bold;
    }
</style>
