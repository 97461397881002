<template>
    <!--  该功能只适用于子账号  -->
    <div v-if="isFather" style="padding: 20px; text-align: center">无权限</div>
    <div v-else>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>账号设置</el-breadcrumb-item>
            <el-breadcrumb-item>收款方式</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card shadow="never" class="box-card">
            <h4>银行卡</h4>
            <div class="create-form-wrapper">
                <el-form ref="bankForm" label-position="left" label-width="100px" :model="bankFormData" :rules="bankRules">
                    <el-form-item label="户名" prop="bankAccountName">
                        <el-input placeholder=""
                                  v-model="bankFormData.bankAccountName"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="开户行" prop="bankAddress">
                        <el-input placeholder=""
                                  v-model="bankFormData.bankAddress"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="账号" prop="bankAccount">
                        <el-input placeholder=""
                                  v-model.number="bankFormData.bankAccount"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary"
                                   v-loading="isBankChanging"
                                   @click="savePayment('BANK')"
                        >确认修改</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" class="box-card">
            <h4>支付宝</h4>
            <div class="create-form-wrapper">
                <el-form ref="zfbForm" label-position="left" label-width="100px" :model="zfbFormData" :rules="zfbRules">
                    <el-form-item label="姓名" prop="ZFBName">
                        <el-input placeholder=""
                                  v-model="zfbFormData.ZFBName"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="账号" prop="ZFBAccount">
                        <el-input placeholder=""
                                  v-model="zfbFormData.ZFBAccount"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary"
                                   v-loading="isZfbChanging"
                                   @click="savePayment('ZFB')"
                        >确认修改</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
  import fetch from '../../utils/fetch';
  import { baseUrl } from '../../utils/env';
  import { mapState } from 'vuex';

  export default {
    name: 'PaymentManage',
    computed: mapState({
      isFather: state => !state.accountInfo.level
    }),
    data() {
      return {
        bankFormData: {
          bankAccountName: '',
          bankAddress: '',
          bankAccount: ''
        },
        bankRules: {
          bankAccountName: [
            { required: true, message: '请输入户名', trigger: 'blur' },
          ],
          bankAddress: [
            { required: true, message: '请输入开户行', trigger: 'blur' },
          ],
          bankAccount: [
            { required: true, message: '请输入账号', trigger: 'blur' },
            { type: 'number', required: true, message: '请输入数字', trigger: 'blur' }
          ],
        },
        zfbFormData: {
          ZFBName: '',
          ZFBAccount: ''
        },
        zfbRules: {
          ZFBName: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
          ],
          ZFBAccount: [
            { required: true, message: '请输入支付宝', trigger: 'blur' },
          ]
        },
        isBankChanging: false,
        isZfbChanging: false
      }
    },
    async created() {
      try {
        const res = await fetch(`${baseUrl}/api/agent/getUserPaymentInfo`);
        if (res.code === 200) {
          const { ZFBAccount, ZFBName, bankAccount, bankAccountName, bankAddress } = res.data;
          try {
            this.zfbFormData = {
              ZFBName,
              ZFBAccount
            };
            this.bankFormData = {
              bankAccount,
              bankAccountName,
              bankAddress
            };
          } catch (e) {
            this.$message({
              message: `加载收款方式异常：${e.message}`,
              type: 'error'
            });
            this.zfbFormData = {};
            this.bankFormData = {};
          }
        } else {
          this.$message({
            message: `加载收款方式失败：${res.message}`,
            type: 'error'
          });
        }
      } catch (e) {
        this.$message({
          message: `加载收款方式失败：${e.message}`,
          type: 'error'
        });
      }
    },
    methods: {
      savePayment(type) {
        this.$refs[type === 'ZFB' ? 'zfbForm' : 'bankForm'].validate(async (valid) => {
          if (!valid) return;
          let obj;
          if (type === 'ZFB') {
            if (this.isZfbChanging) return;
            this.isZfbChanging = true;
            obj = {
              ...this.zfbFormData,
              type
            };
          } else if (type === 'BANK') {
            if (this.isBankChanging) return;
            this.isBankChanging = true;
            obj = {
              ...this.bankFormData,
              type
            };
          } else {
            return;
          }
          try {
            const res = await fetch(`${baseUrl}/api/agent/updateUserPaymentInfo`, obj, 'POST');
            if (res.code === 200) {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
            } else {
              this.$message({
                message: `修改失败：${res.message}`,
                type: 'error'
              });
            }
          } catch (e) {
            this.$message({
              message: `修改失败：${e.message}`,
              type: 'error'
            });
          }
          if (type === 'ZFB') {
            this.isZfbChanging = false;
          } else if(type === 'BANK') {
            this.isBankChanging = false;
          }
        });
      }
    }
  };
</script>

<style scoped>
    .box-card {
        margin: 10px 0;
    }
    .create-form-wrapper {
        width: 70%;
        margin: 10px auto 0;
    }
</style>
