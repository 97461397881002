<template>
  <div
    class="header"
    v-bind:class="type"
  >
    <el-row class="header-panel">
      <el-col
        :span="4"
        class="icon-banner"
      >
        <div class="grid-content bg-purple">
          <a
            class="logo"
            href="https://bonuscloud.io"
          >
            <img
              class="header-logo "
              alt="header-logo"
              src="../assets/logo.png"
            >
          </a>
        </div>
      </el-col>
      <el-col
        :span="20"
        class="header-login-panel"
      >
        <div
          class="header-panel-wrap"
          v-if="type !== 'login-header' && type !== 'protocol-header'"
        >
          <router-link
            to="/"
            class="data-panel"
          >
            <div
              class="header-date-panel header-each-tab"
              v-bind:class="{opacityPercent: !(dataPanelStyle) }"
            >
              <img
                class="menu-icon home-icon"
                src="../assets/home/data-panel-login.png"
              >
              {{ $t('header.dataPanel') }}
            </div>
          </router-link>
          <router-link
            to="/hardList"
            class="hard-device-wrapper"
          >
            <div
              class="account-settings header-each-tab"
              v-bind:class="{opacityPercent: !(thisPath === '/hardList' || thisPath === '/standardNode' || thisPath === '/dialConfig' || thisPath === '/standardList')}"
            >
              <img
                class="menu-icon home-icon"
                src="../assets/accountSet/hard-list.png"
              >
              {{ $t('header.hardware') }}
            </div>
          </router-link>
          <router-link
            to="/accountSet"
            class="account-settings-wrapper"
          >
            <div
              class="account-settings header-each-tab"
              v-bind:class="{opacityPercent:  !(thisPath === '/bindWallet' || thisPath === '/accountSet' || thisPath === '/contact' || thisPath === '/withdrawalAuto')  }"
            >
              <img
                class="menu-icon home-icon"
                src="../assets/home/account-setting.png"
              >
              {{ $t('header.accountSetting') }}
            </div>
          </router-link>
          <div class="banner-club header-each-tab">
            <a
              target="_blank"
              class="club-a"
              :href="$t('header.club_href')"
            ><img
                class="menu-icon home-icon"
                src="../assets/club.png"
              > {{
              $t('header.club') }}</a>
          </div>
          <div class="header-email header-each-tab">{{email}}</div>
          <div
            class="header-logout header-each-tab  bonus-cursor exit"
            @click="clickLogout"
          >{{ $t('header.logout') }}
          </div>
        </div>
        <!-- <div
          v-if="!whichLanguage && type !== 'protocol-header'"
          class="header-language header-each-tab  bonus-cursor lang"
          @click="changeLanguage('zn')"
        >中文
        </div>
        <div
          v-if="whichLanguage && type !== 'protocol-header'"
          class="header-language header-each-tab  bonus-cursor lang"
          @click="changeLanguage('en')"
        >English
        </div> -->
      </el-col>
      <el-col
        class="menu-wrapper"
        :span="1"
      >
        <div
          class="icon-menu"
          @click="toggleShowMenu"
        ></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Header",
  props: {
    type: ""
  },
  data() {
    return {
      whichLanguage: false
    };
  },
  computed: mapState({
    email: state => state.account.email,
    loginStatus: state => state.account.loginStatus,
    isZnWebsite: state => state.isZnWebsite,
    thisPath() {
      return this.$route.path;
    },
    dataPanelStyle() {
      let thisPath = this.$route.path;
      return (
        thisPath === "/" ||
        thisPath === "/revenue" ||
        thisPath === "/withdrawal" ||
        thisPath === "/inviteDetail" ||
        thisPath === "/netStatus" ||
        thisPath === "/calcuteStatus" ||
        thisPath === "/calculateStatus" ||
        thisPath === "/netDetail" ||
        thisPath === "/calculateDetail" ||
        thisPath === "/networkContribution" ||
        thisPath === "/deviceOverview" || 
        thisPath === "/devAllRevenue" || 
        thisPath === "/calculateTask" ||
        thisPath === "/exchange" || 
        thisPath === "/standardStatus" || 
        thisPath === "/standardWatch" || 
        thisPath === "/dialStatus" ||
        thisPath === "/buyBackSecond"
      );
    }
  }),
  methods: {
    ...mapActions(["logout", "getUserInfo"]),
    // 注销
    clickLogout() {
      this.logout();
    },
    toggleShowMenu() {
      this.$parent.toggleShowMenu();
    }
  },
  created() {
    //  用户账号信息获取
    if (
      !this.email &&
      this.type !== "login-header" &&
      this.type !== "protocol-header"
    ) {
      // 获取用户信息
      this.getUserInfo();
    } else if (this.email && this.type === "login-header") {
      this.$router.push({ name: "home" });
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.is-mobile {
  .menu-wrapper {
    width: 25px;
  }

  .icon-menu {
    margin-top: 18px;
    padding-right: 30px;
    width: 25px;
    height: 25px;
    display: block;
    margin-right: 10px;
    background: url('../assets/menu.svg') 0 no-repeat;
    background-size: 25px 25px;
  }

  .login-header {
    .icon-menu {
      display: none;
    }

    .header-language {
      right: 50px;
    }
  }

  .icon-banner {
    width: auto;
  }

  .header-logo {
    margin: 19px 0 0 0;
  }

  .panel-wrap {
    position: relative;
    z-index: 10;
  }

  .header-each-tab {
    margin-left: 15px;
  }

  .hard-device-wrapper, .account-settings-wrapper, .header-logout, .data-panel, .banner-club, .header-language, .header-email {
    display: none;
  }

  .header-panel {
    display: flex;
    justify-content: space-between;
  }

  .home-header {
    box-sizing: border-box;
  }
}

.icon-menu {
  display: none;
}

.header-panel-wrap {
  display: flex;
}

.header {
  font-size: 18px;
  background: #171E22;
  width: 100%;
  height: 62px;
  position: relative;
  display: flex;
}

.header-logo {
  width: 130px;
  height: 23.5px;
  margin: 19px 25px;
}

.login-header, .protocol-header {
  background: #000519;
}

.home-header {
  background: #000519;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.line {
  border-bottom: 1px solid #979797;
  opacity: 0.3;
  width: 100%;
  top: 62px;
  position: absolute;
}

.header-each-tab {
  height: 62px;
  line-height: 62px;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 62px;
  margin-left: 50px;
}

.header-panel {
  width: 100%;
  text-align: left;
}

.header-login-panel {
  display: flex;
  position: relative;
}

.account-settings {
  margin-left: 50px;
}

.header-logout {
  position: absolute;
  right: 30px;
}

.header-email {
  position: absolute;
  right: 100px;
  padding-left: 1px solid #E7EAEC;
}

.opacityPercent {
  opacity: 0.4;
}

.header-language {
  position: absolute;
  right: 100px;
  text-align: center;
  width: 40px;
}

.club-a {
  color: #fff;
}

.header-club {
  position: absolute;
  right: 360px;
}
</style>
