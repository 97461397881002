import Vue from 'vue';
import Vuex from 'vuex';
import { isZnWebsite } from '../utils/isChinaWebsite';
import mutations from './mutation';
import actions from './action';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accountInfo: {},
    isZnWebsite,
  },
  mutations,
  actions,
});

