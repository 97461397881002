<template>
    <div class="annouce">
        <el-card class="box-card" shadow="never">
            <div slot="header">
                <span>{{ $t('local_annouce_title') }}</span>
            </div>
            <div class="annouce-content">
                <el-table
                    :data="tableData"
                    style="width: 100%"
                >
                    <el-table-column prop="date" height="40px" width="100px"></el-table-column>
                    <el-table-column>
                        <template slot-scope="scope">
                            <a
                                target="_blank"
                                :href="scope.row.link"
                            >
                                {{scope.row.content}}
                            </a>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
</template>

<i18n>
    {
    "en": {
    "annouce_more": "more"
    },
    "zn": {
    "annouce_more": "查看更多"
    }
    }
</i18n>

<script>
  export default {
    name: "ResouceIndex",
    computed: {
      // 计算属性的 getter
      tableData: function() {
        // `this` 指向 vm 实例
        return Object.values(this.$t("local_annouce"));
      }
    }
  };
</script>

// 公告展示内容
<i18n>
    {
    "en": {
    "local_annouce_title": "Annoucement",
    "local_annouce": []
    },
    "zn": {
    "local_annouce_title": "公告",
    "local_annouce": []
    }
    }
</i18n>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="stylus">
    .annouce .el-table__header-wrapper {
        display: none;
    }
    a {
        color: #96999b;
    }
</style>
