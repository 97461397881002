<template>
    <!--  该功能只适用于子账号  -->
    <div v-if="isFather" style="padding: 20px; text-align: center">无权限</div>
    <div v-else>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>资源管理</el-breadcrumb-item>
            <el-breadcrumb-item>设备状态收益</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card shadow="never" class="box-card">
            <h4>数据总览</h4>
            <el-row class="data-row">
                <el-col :span="8" class="grid-content">
                    <h4>矿机总量</h4>
                    <p>{{ total === undefined ? '--' : total }}&nbsp;台</p>
                </el-col>
                <el-col :span="8" class="grid-content">
                    <h4>离线设备数量</h4>
                    <p>{{ offlineTotal === undefined ? '--' : offlineTotal }}&nbsp;台</p>
                </el-col>
                <el-col :span="8" class="grid-content">
                    <h4>本月当前预估总收益</h4>
                    <p>{{totalEarning? parseInt(totalEarning) :'0'  }}&nbsp;元</p>
                </el-col>
            </el-row>
        </el-card>
        <el-card shadow="never" class="box-card">
            <h4>节点运行状态</h4>
            <div v-loading="loading">
                <el-table
                        :data="tableData"
                        style="width: 100%; margin-bottom: 10px">
                    <el-table-column
                            prop="serverHostname"
                            label="主机名"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            prop="serverNote"
                            label="备注">
                    </el-table-column>
                    <el-table-column
                            prop="cpu_count"
                            label="CPU">
                    </el-table-column>
                    <el-table-column
                            prop="mem_size"
                            label="内存">
                    </el-table-column>
                    <el-table-column
                            prop="storage_size"
                            label="硬盘">
                    </el-table-column>
                    <el-table-column
                            prop="operator"
                            label="运营商">
                    </el-table-column>
                    <el-table-column
                            prop="provinceAndCity"
                            label="归属地">
                    </el-table-column>
                    <el-table-column
                            prop="serverOnlineDate"
                            label="上线时间">
                    </el-table-column>
                    <!-- <el-table-column
                            prop="brandWidthAndEarnings"
                            label="当前月95峰">
                        <template slot-scope="scope">
                            <span>{{ scope.row.brandWidthAndEarnings.brandWidth || 0 }} Gbps</span>
                        </template>
                    </el-table-column> -->
                    <!-- 日95峰 -->
                      <el-table-column
                              prop="brandWidthAndEarnings"
                              label="当前日95峰"
                              align='center'
                              >
                          <template slot-scope="scope">
                             <img class="day95_line" src="../../assets/revenueBlack.png" alt="" @click="showDay95line(scope)">
                          </template>
                      </el-table-column> 
                    <el-table-column
                            prop="brandWidthAndEarnings"
                            label="当月预估收益">
                        <template slot-scope="scope">
                            <span>{{eachdayearnlist[scope.row.serverBcode]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="serverServiceStatus"
                            label="服务状态">
                    </el-table-column>
                    <el-table-column
                            prop="status"
                            label="在线状态">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status === 'online'" type="success">{{ scope.row.status }}</el-tag>
                            <el-tag v-if="scope.row.status === 'offline'" type="danger">{{ scope.row.status }}</el-tag>
                        </template>
                    </el-table-column>
<!--                    <el-table-column label="操作">-->
<!--                        <template slot-scope="scope">-->
<!--                            <el-button size="mini" @click="handleDelete(scope.$index, scope.row)">解绑</el-button>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                </el-table>
            </div>
        </el-card>
        <el-dialog
                title="解绑设备"
                :visible.sync="confirmDialogVisible"
                width="480px"
                center
        >
            <!-- <div class="unbind-dialog-wrap">
                <span class="key verify-key">图像验证</span>
                <div class="hard-captcha">
                    <vue-recaptcha
                            class="captcha-wrap"
                            ref="recaptcha"
                            @verify="onVerify"
                            @expired="onExpired"
                            data-size="normal"
                            :sitekey="sitekey"
                    />
                </div>
            </div> -->
            <div class="unbind-dialog-wrap">
                <span class="key mail-key">手机验证码</span>
                <SendEmailCode
                        type="text"
                        imageStyle="unbind-style"
                        class="unbind-input password-email"
                        v-model="inputEmailCode"
                        needImageCode=true
                        :email="email"
                        @emailCodeTip="emailCodeTip"
                ></SendEmailCode>
            </div>
            <span
                    slot="footer"
                    class="dialog-footer"
            >
                <el-button @click="confirmDialogVisible = false">取消</el-button>
                <el-button v-loading="isDeleting" type="primary" @click="unbind">确定</el-button>
            </span>
        </el-dialog>
        <!-- 日95峰图 -->
        <el-dialog 
          title="当前日95峰图"
          :visible="show95line"
          width="60%"
          @close="closeShow95line"
          center>
          <div class="selectbox">
            <span class="search-text">{{$t('watchDetail.watchDate')}}:</span>
<!--            <el-select
              v-model="searchWatchDate"
              filterable
              placeholder=""
              @change="showWatchDetail"
            >
              <el-option
                v-for="item in watchQueryDate"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select> -->
            <el-date-picker
              :picker-options="pickerOptions1"
              v-model="selectDate1"
              type="daterange"
              @change="getDay95linedata"
              :clearable="true"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <ve-line                 
            class="watch-chart"
            :data="chartData"
            :extend="extend"
          ></ve-line>
        </el-dialog>
    </div>
</template>

<script>
  import VueRecaptcha from "vue-recaptcha";
  import SendEmailCode from "@/components/SendEmailCode.vue";
  import { SITEKEY } from "../../config/contant.js";
  import fetch from '../../utils/fetch';
  import { baseUrl } from '../../utils/env';
  import { mapState } from "vuex";
  import moment from "moment";
  export default {
    name: 'DeviceList',
    components: {
      VueRecaptcha,
      SendEmailCode
    },
    computed: mapState({
      email: state => state.accountInfo.email,
      isFather: state => !state.accountInfo.level,
      // 监控查询过去30天时间
      watchQueryDate: () => {
        let watchQueryDate = [];
        let endDay = moment()
          .utc()
          .startOf("day");
  
        // endDay -= 24 * 60 * 60 * 1000;
        let i = 0;
        while (i < 30) {
          i++;
          watchQueryDate.push(moment(endDay).format("YYYY-MM-DD"));
          endDay -= 24 * 60 * 60 * 1000;
        }
  
        return watchQueryDate;
      },
    }),
    data() {
      return {
        total: undefined,
        // earningTotal: undefined,
        offlineTotal: undefined,
        loading: false,
        tableData: [],
        confirmDialogVisible: false,
        isDeleting: false,
        inputEmailCode: '', // 输入的邮件码
        response: '',
        sitekey: SITEKEY['LOW'], // ga verify key
        unbindId: '',
        show95line:false,
        searchWatchDate: moment()
          .utc()
          .startOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        chartData: {
          columns: ['时间', '日95峰(Gbps)'],
          rows: []
        },
        selectDate1:new Date(),
        pickerOptions1:{
         //最多可选择60天
          disabledDate(time){
            let _now = Date.now(),
            twomonth = 60 * 24 * 60 * 60 * 1000,
            twomonthDays = _now - twomonth;
            return time.getTime() > _now || time.getTime() < twomonthDays;
          }
        },
        dayearnlist:[], //日收益列表
        eachdayearnlist:{},
        showlineScope:"",
        totalEarning:"",//本月当前预估总收益
        extend: {
          series:{
            type:'line',
            itemStyle:{
              normal:{
                color:'#282557',//改变折点颜色
                lineStyle:{
                  color:'#282557', //改变折线颜色
                }
              }
            },
          }
        },
      }
    },
    async created() {
      let tableData = await this.onQuery();
      this.dayearnlist=[];
      //获取日95峰数据
      const new95Bps = {};
      tableData.forEach(d => {
        new95Bps[d.serverBcode] = d.serverBcode;
      });
      const brandWidthRes = await fetch(`${baseUrl}/api/agent/getDay95listByBcode`, {
        serverBcode: JSON.stringify(new95Bps),
      });
  
      //获取价格
       const deviceInfo = {}
       tableData.forEach(d => {
         deviceInfo[d.serverBcode] = d.operator;
       });
       const brandWidthRes1 = await fetch(`${baseUrl}/api/agent/getBrandWidthByBcode`, {
         deviceInfo: JSON.stringify(deviceInfo)
       });
  
      const eachEarninglist = [];
      for ( let [key,val] of Object.entries(brandWidthRes.data)){
        // const daylist = val.map(function(item){
        //   return Number((item.sum_bps / (1024 * 1024 * 1024)).toFixed(2))
        // })
  
        const dayer = brandWidthRes.data.[key].map(function(item){
          return Number((item.sum_bps / (1024 * 1024 * 1024))*(brandWidthRes1.data[key].price / 30).toFixed(2))
        })
        let sum = 0;
        for(let i = 0;i<dayer.length;i++){
          sum = sum + dayer[i];
        }
  
       this.eachdayearnlist[key] = Number(sum.toFixed(2));
  
       eachEarninglist.push(this.eachdayearnlist[key]);
       this.tableData = tableData;
      }
  
      var sum1 = 0;
      for(var i = 0;i<eachEarninglist.length;i++){
        sum1 += eachEarninglist[i];
      }
      this.totalEarning = sum1;
    },
    methods: {
      // email错误提示
      emailCodeTip(error) {
        if (error.message) {
          this.$message({
            type: "error",
            message: error.message
          });
        }
      },
      // onVerify(response) {
      //   this.response = response;
      // },
      // onExpired() {
      //   this.$message({
      //     message: '验证码已过期',
      //     type: "error"
      //   });
      //   this.$refs.recaptcha.reset();
      // },
      // 删除设备
      unbind() {
        if (this.isDeleting) return;
        this.isDeleting = true;
        fetch(`${baseUrl}/api/web/devices/unbind/`, {
          deviceId: this.unbindId,
          emailVerifyCode: this.inputEmailCode
        }, 'POST').then(res => {
          this.$refs.recaptcha.reset();
          if (res.code === 200) {
            // 刷新设备列表
            this.onQuery();
            this.confirmDialogVisible = false;
            this.$message({
              type: "success",
              message: res.message
            });
          } else {
            this.$message({
              type: "error",
              message: res.message || "unbind error"
            });
          }
          this.isDeleting = false;
        }).catch(e => {
          this.$message({
            type: 'error',
            message: e.message
          });
          this.isDeleting = false;
        });
      },
      async onQuery() {
        // this.loading = true;
        try {
          const res = await fetch(`${baseUrl}/api/standardNode/getStandardNode`, { deviceInfo: true });
          if (res.status === 200) {
            const list = res.data || [];
            if (!list.length) {
              this.loading = false;
              return;
            }
  
            const deviceInfo = {}
            list.forEach(d => {
              deviceInfo[d.serverBcode] = d.operator;
            });
            const brandWidthRes = await fetch(`${baseUrl}/api/agent/getBrandWidthByBcode`, {
              deviceInfo: JSON.stringify(deviceInfo)
            });
  
            if (brandWidthRes.code === 200) {
              list.forEach(device => {
                device.brandWidthAndEarnings = brandWidthRes.data[device.serverBcode] || {};
              });
              // this.tableData = list.map(d => ({
              let newList = list.map(d => ({
                ...d,
                provinceAndCity: `${d.province}-${d.city}`
              }));
              this.total = list.length;
              this.offlineTotal = list.filter(d => d.status === 'offline').length;
              // const earningTotal = list.reduce((total, curr) => {
              //   return total + (curr.brandWidthAndEarnings.earnings || 0)
              // }, 0);
              // if (typeof earningTotal === 'object') {
              //   this.earningTotal = earningTotal.brandWidthAndEarnings.earnings;
              // } else {
              //   this.earningTotal = earningTotal;
              // }
  
                return newList;
            } else {
              this.$message({
                message: `查询失败：${brandWidthRes.message}`,
                type: 'error'
              });
            }
          } else {
            this.$message({
              message: `查询失败：${res.message}`,
              type: 'error'
            });
          }
        } catch (e) {
          this.$message({
            message: `查询失败：${e.message}`,
            type: 'error'
          });
        }
        this.loading = false;
      },
      handleDelete(index, row) {
        this.confirmDialogVisible = true;
        this.unbindId = row.id;
      },
      //展开日95峰图
      async showDay95line(scope){
        this.show95line = true;
        //获取95峰数据
        const new95Bps = {};
        this.tableData.forEach(d => {
          new95Bps[d.serverBcode] = d.serverBcode;
        });
        const brandWidthRes = await fetch(`${baseUrl}/api/agent/getDay95listByBcode`, {
          serverBcode: JSON.stringify(new95Bps),
        });
        this.handleChartData(brandWidthRes.data.[scope.row.serverBcode] || []);
        this.showlineScope = scope.row.serverBcode;
      },
      //关闭日95峰图
      closeShow95line(){
        this.show95line = false;
      },
      //选择日期范围
      async getDay95linedata() {
        const year = this.selectDate1[0].getFullYear();
        const month = this.selectDate1[0].getMonth();
        const date = this.selectDate1[0].getDate();
        const dateAfter = new Date(year, month, date, 0, 0, 0).getTime();
  
        const year1 = this.selectDate1[1].getFullYear();
        const month1 = this.selectDate1[1].getMonth();
        const date1 = this.selectDate1[1].getDate();
        const dateBefore = new Date(year1, month1, date1, 23, 59, 59).getTime();
  
        //点击日期获取95峰数据
        const new95Bps = {};
        this.tableData.forEach(d => {
          new95Bps[d.serverBcode] = d.serverBcode;
        });
        const brandWidthRes = await fetch(`${baseUrl}/api/agent/getDay95listByBcode`, {
          serverBcode: JSON.stringify(new95Bps),
          dateAfter,
          dateBefore,
        });
        this.handleChartData(brandWidthRes.data.[this.showlineScope] || []);
      },
  
      handleChartData (data) {
        const rows = data.map(d => ({
          '时间': moment(new Date(d.time)).format("YYYY-MM-DD HH:mm:ss"),
          '日95峰(Gbps)': Number((d.sum_bps / (1024 * 1024 * 1024)).toFixed(2))
        }));
        this.chartData = {
          columns: ['时间', '日95峰(Gbps)'],
          rows
        };
      },
    }
  };
</script>

<style scoped>
    .box-card {
        margin: 10px 0;
    }
    .data-row {
        margin-top: 10px;
    }
    .grid-content {
        padding: 20px;
        border: 1px solid lightgray;
    }
    .grid-content h4 {
        margin-bottom: 20px;
    }
    .grid-content p {
        font-weight: bolder;
        font-size: 25px;
    }
    .el-loading-spinner .path{
      stroke: #282557;
    }
    .watch-chart{
      margin-top: 30px;
    }
    .day95_line{
      width: 20px;
      height: 20px;
      margin-top: 8px;
      display: inline-block;
      cursor: pointer;
    }
    .selectbox{
      text-align: center;
      margin-top: 20px;
    }
</style>
