<template>
    <div v-if="loading">正在加载中...</div>
    <el-container style="height: 100%" v-else>
        <el-header class="header-wrapper">
            <div class="logo-wrapper">
                <img class="header-logo" alt="header-logo" src="../../assets/aaa.png" />
            </div>
            <el-menu :default-active="activeMenu"
                     class="el-menu"
                     mode="horizontal"
                     background-color="rgb(17,17,53)"
                     text-color="rgb(152, 154, 162)"
                     active-text-color="#fff"
            >
                <el-menu-item index="RESOURCE" @click="visit('RESOURCE')">资源管理</el-menu-item>
                <el-menu-item v-if="!isFather" index="WALLET" @click="visit('WALLET')">我的钱包</el-menu-item>
                <el-menu-item index="ACCOUNT" @click="visit('ACCOUNT')">账户设置</el-menu-item>
            </el-menu>
            <div class="right-action">
                <span>{{ email }}</span>
                <span class="quit" @click="loginOut">退出</span>
            </div>

        </el-header>
        <router-view></router-view>
    </el-container>
</template>

<script>
  import Footer from '@/components/Common/Footer.vue';
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'Layout',
    components: {
      Footer
    },
    computed: mapState({
      email: state => state.accountInfo.email,
      isFather: state => !state.accountInfo.level
    }),
    data () {
      return {
        loading: false,
        activeMenu: ''
      };
    },
    methods: {
      ...mapActions([
        'getAgentUserInfo',
        'loginOut'
      ]),
      visit(menuName) {
        switch (menuName) {
          case 'RESOURCE':
            this.$router.push({ name: 'resourceIndex' });
            break;
          case 'WALLET':
            this.$router.push({ name: 'paymentManage' });
            break;
          case 'ACCOUNT':
            this.$router.push({ name: 'passwordManage' });
            break;
          default:
            break;
        }
        this.activeMenu = menuName;
      }
    },
    async created() {
      this.loading = true;
      if (window.location.pathname.startsWith('/resource/')) {
        this.activeMenu = 'RESOURCE';
      } else if (window.location.pathname.startsWith('/wallet/')) {
        this.activeMenu = 'WALLET';
      } else if (window.location.pathname.startsWith('/account/')) {
        this.activeMenu = 'ACCOUNT';
      }
      await this.getAgentUserInfo();
      this.loading = false;
    }
  };
</script>

<style scoped>
    .header-wrapper {
        position: relative;
        background-color: rgb(17,17,53);
    }

    .logo-wrapper {
      display: inline-block;
      height: 60px;
      float: left;
      height: 60px;
      line-height: 60px;
      border-bottom: 2px solid transparent;
      font-weight: bold;
      width: 180px;
      position: relative;
    }

    .logo-wrapper .header-logo {
        /* height: 23.5px; */
        /* width: 69%; */
        width: 120%;
        position: absolute;
        top: -72px;
        left: -38px;
    }

    .el-menu {
        display: inline-block;
    }

    .right-action {
        color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        line-height: 60px;
        right: 20px;
    }

    .quit {
        display: inline-block;
        margin-left: 30px;
        cursor: pointer;
    }

    .el-menu .is-active {
        border-left: 0;
    }
</style>
<style>
    .el-submenu__title:hover,
    .el-menu-item:hover {
        background-color: rgb(8, 11, 27) !important;
    }
</style>
