/** 联系页面的手机验证码（需要提供手机号绑定） **/
<template>
  <div class="BasicInput EmailBaseInput">
    <div class="email_wrap">
      <div
        v-if="iconType"
        class="icon-wrap"
      >
        <div
          v-bind:class="iconType"
          class="icon"
        ></div>
      </div>
      <input
        class="basic-input bonus-big-input"
        v-bind:placeholder="placeValue"
        v-bind:type="type"
        v-model="inputValue"
        v-on:input="$emit('change', $event.target.value)"
      />
      <div
        v-if="sendStatus"
        class="send-code bonus-cursor"
        v-bind:class="imageStyle"
        v-on:click="sendCode"
      >发送验证码</div>
      <div
        v-if="!sendStatus"
        class="send-code"
        v-bind:class="imageStyle"
      >{{ count }}</div>
    </div>

  </div>
</template>

<script>
import { Message } from "element-ui";
import fetch from '../../utils/fetch';
import { baseUrl } from '../../utils/env';

export default {
  name: "send-email",
  props: {
    iconType: "", // icon图地址
    defaultValue: "",
    placeValue: "", // input placehoder
    type: "", // input类型,
    value: "",
    imageCodeSrc: "", //图片验证码地址,
    tel: "", //邮箱地址
    imageCode: "", // 图片验证码
    imageStyle: "", //绑定页面特定样式
  },
  model: {
    prop: "value",
    event: "change",
  },
  components: {},
  data() {
    return {
      inputValue: this.value,
      sendStatus: true, // 验证码点击状态
      TIME_COUNT: 60, //倒计时时间
      count: "", // 计数
      timer: null, // 记录循环
      time: 60, // 发送验证码倒计时
      sendMsgDisabled: false,
      response: "", //ga verify response
    };
  },
  methods: {
    sendVerifyCode(data) {
      return fetch(baseUrl + '/api/tel/sendVerifyCode', data, 'POST');
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
    },
    sendCode() {
      let that = this;
      let { tel } = this;

      if (!tel || !/^1\d+$/.test(tel)) {
        that.$emit("verifyCodeTip", {
          type: "tel",
          message: "手机错误",
        });
        return;
      } else {
        that.$emit("verifyCodeTip", {
          type: "tel",
          message: "",
        });
      }

      // 倒计时
      this.startCountBack();

      this.sendVerifyCode({
        tel,
      }).then((res) => {
        if (res.code === 200) {
          // that.sendStatus = true;
          Message({
            message: "短信发送成功",
            type: "success",
          });
        } else if (!res.ret) {
          // that.sendStatus = true;
          Message({
            message: res.message || "本地网络超时，请重试",
            type: "error",
          });
        }
      });
    },
    startCountBack() {
      let that = this;
      that.sendStatus = false; //倒计时
      const TIME_COUNT = that.TIME_COUNT;
      that.count = TIME_COUNT;
      that.timer = setInterval(() => {
        if (that.count > 0 && that.count <= TIME_COUNT) {
          that.count--;
        } else {
          that.show = true;
          clearInterval(that.timer);
          that.timer = null;
          that.sendStatus = true;
        }
      }, 1000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.input.basic-input[data-v-33a4746f] {
  height: 30px;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #000;
  display: inline-block;
  font-size: inherit;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  font-size: 10px;
}

.send-code {
  font-family: PingFangSC-Regular;
  font-size: 10px;
  color: #282557;
  text-align: right;
  position: absolute;
  right: 5px;
  line-height: 40px;
}

.email_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .email_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0px;
  }
}

.email_wrap {
  display: flex;
}

.BasicInput {
  height: 40px;
  display: flex;
  position: relative;
  width: 100%;
}

.icon-wrap {
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  background: #F1F2F2;
  border: 1px solid #D2D5D6;
  border-right: 0px;
  background-size: 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 10px;
  height: 10px;
  background-size: contain;
}

</style>

