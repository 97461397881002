<template>
    <div v-if="!isFather" style="padding: 20px; text-align: center">无权限</div>
    <div v-else>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>资源管理</el-breadcrumb-item>
            <el-breadcrumb-item>设备列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card shadow="never" class="box-card">
            <h4>节点运行状态</h4>
            <el-form :inline="true" :model="condition" style="margin: 10px 0">
                <el-form-item label="搜索">
                    <el-select v-model="condition.queryType">
                        <el-option label="主机名" value="DEVICE"></el-option>
                        <el-option label="备注" value="REMARK"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="condition.queryStr" placeholder="输入要搜索的信息"></el-input>
                </el-form-item>
                <el-form-item label="子账户">
                    <el-select v-model="condition.slaveOwner" filterable clearable placeholder="请选择">
                        <el-option
                                v-for="item in accountList"
                                :key="item.id"
                                :label="item.email"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="服务状态">
                    <el-select v-model="condition.serviceStatus" filterable clearable placeholder="请选择">
                        <el-option label="部署" value="部署"></el-option>
                        <el-option label="运行" value="运行"></el-option>
                        <el-option label="下线" value="下线"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="在线状态">
                    <el-select v-model="condition.status" filterable clearable placeholder="请选择">
                        <el-option label="online" value="online"></el-option>
                        <el-option label="offline" value="offline"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onQuery">查询</el-button>
                </el-form-item>
            </el-form>
            <div v-loading="loading">
                <p style="margin: 10px 0;">设备总数：{{ total }}</p>
                <el-table
                        :data="tableData"
                        style="width: 100%; margin-bottom: 10px">
                    <el-table-column
                            prop="ServerHostname"
                            label="主机名"
                            width="180"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="ServerNote"
                            label="备注"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="Email"
                            label="子账户"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="CPU"
                            label="CPU"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="MemSize"
                            :formatter="formatMemSize"
                            label="内存"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="IntStorageSize"
                            :formatter="formatIntStorageSize"
                            label="硬盘"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="Operator"
                            label="运营商"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="City"
                            label="归属地"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="ServerOnlineDate"
                            label="上线时间"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="BandwidthMb"
                            label="带宽"
                            align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.BandwidthMb }} M</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="ServerService"
                            label="运行业务"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="ServerServiceStatus"
                            label="服务状态"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="DevStatus"
                            label="在线状态"
                            align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.DevStatus === 'online'" type="success">{{ scope.row.DevStatus }}</el-tag>
                            <el-tag v-if="scope.row.DevStatus === 'offline'" type="danger">{{ scope.row.DevStatus }}</el-tag>
                        </template>
                    </el-table-column>
                    <!-- 登陆IP -->
                    <el-table-column
                            align='center'
                            prop="ServerJumperIP"
                            label="登陆IP">
                    </el-table-column>
                    <!-- 设备密码 -->
                    <el-table-column
                            width="100"
                            prop="ServerPassword"
                            label="设备密码"
                            align="center">
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="handleDelete(scope.$index, scope.row)">解绑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <el-dialog
            title="解绑设备"
            :visible.sync="confirmDialogVisible"
            width="480px"
            center
        >
            <div class="unbind-dialog-wrap">
              <span class="key verify-key">图像验证</span>
              <div class="hard-captcha">
                <div style="display: flex;align-items: center; justify-content: space-between; margin-bottom: 20px;">
                  <input
                    type="text"
                    class="input"
                    style="font-size: 12px; height: 40px;"
                    v-model="imageCode"
                    autocomplete="off"
                  />
                  <img style="margin-left: 10px;":src="imgUrl" />
                  <div style="width: 110px;">
                    <p style="margin-left: 10px; font-size: 14px;" class="image-remark">看不清？<span style="color: #111135; cursor: pointer;text-decoration: underline;" @click="queryimageCode">换一张</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="unbind-dialog-wrap">
                <span class="key mail-key">手机验证码</span>
                <SendEmailCode
                    type="text"
                    imageStyle="unbind-style"
                    class="unbind-input password-email"
                    v-model="inputEmailCode"
                    needImageCode=true
                    :email="email"
                    @emailCodeTip="emailCodeTip"
                ></SendEmailCode>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="confirmDialogVisible = false">取消</el-button>
                <el-button v-loading="isDeleting" type="primary" @click="unbind">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
  import VueRecaptcha from "vue-recaptcha";
  import SendEmailCode from "@/components/SendEmailCode.vue";
  import { SITEKEY } from "../../config/contant.js";
  import fetch from '../../utils/fetch';
  import { baseUrl } from '../../utils/env';
  import { mapState } from "vuex";
  import { reqUrl } from '../../utils/env';
  import axios from 'axios';
  export default {
    name: 'DeviceList',
    components: {
      VueRecaptcha,
      SendEmailCode
    },
    computed: mapState({
      email: state => state.accountInfo.email,
      isFather: state => !state.accountInfo.level
    }),
    data() {
      return {
        condition: {
          queryType: 'DEVICE',
          queryStr: '',
          slaveOwner: '',
          serviceStatus: '',
          status: ''
        },
        inputEmailCode: '', // 输入的邮件码
        response: '',
        sitekey: SITEKEY['LOW'], // ga verify key
        unbindId: '',
        accountList: [],
        total: 0,
        loading: false,
        tableData: [],
        confirmDialogVisible: false,
        isDeleting: false,
        currentDevice: {},
        imageCode:'',
        imgUrl: '',
        imageCodeId: ''
      };
    },
    async created() {
      this.queryimageCode();
      if (this.isFather) {
        await this.getSonAccountList();
      }
    },
    methods: {
      queryimageCode() {
        this.imageCode = '';
        try {
          axios.get(`${reqUrl}/api/getCaptcha`).then(res=>{
            this.imgUrl = res.data.data.Base64Blob;
            this.imageCodeId = res.data.data.CaptchaId;
          })
        } catch (error) {
            this.imgUrl = '';
            this.imageCodeId = '';
        }
      },
      // email错误提示
      emailCodeTip(error) {
        if (error.message) {
          this.$message({
            type: "error",
            message: error.message
          });
        }
      },
      // 删除设备
      unbind() {
        if (this.isDeleting) return;
        this.isDeleting = true;
        fetch(`${baseUrl}/api/web/devices/unbind/`, {
          deviceId: this.unbindId,
          emailVerifyCode: this.inputEmailCode,
          imageCodeId: this.imageCodeId,
          imageCode: this.imageCode
        }, 'POST').then(res => {
          if (res.code === 200) {
            // 刷新设备列表
            this.onQuery();
            this.confirmDialogVisible = false;
            this.$message({
              type: "success",
              message: res.message
            });
          } else {
            this.$message({
              type: "error",
              message: res.message || "unbind error"
            });
          }
          this.isDeleting = false;
        }).catch(e => {
          this.$message({
            type: 'error',
            message: e.message
          });
          this.isDeleting = false;
        });
      },
      async getSonAccountList() {
        const res = await fetch(`${baseUrl}/api/agent/getSonAccountList`);
        if (res.code === 200 && Array.isArray(res.data) && res.data.length) {
          this.accountList = res.data;
        }
        this.onQuery();
      },
      async onQuery() {
        // this.loading = true;
        try {
          const res = await fetch(`${baseUrl}/api/agent/getDeviceListOfFather`, this.condition);
          if (res.code === 200) {
            this.tableData = res.data.DevList || [];
            this.total = (res.data.DevList || []).length;
          } else {
            this.$message({
              message: `查询失败：${res.message}`,
              type: 'error'
            });
          }
        } catch (e) {
          this.$message({
            message: `查询失败：${e.message}`,
            type: 'error'
          });
        }
        this.loading = false;
      },
      formatMemSize(row) {
         return parseInt(row.MemSize /1000 /1000 /1000)
      },
      formatIntStorageSize(row){
        return parseInt(row.IntStorageSize /1000 /1000 /1000)
      },
      handleDelete(index, row) {
        this.confirmDialogVisible = true;
        this.unbindId = row.DeviceId;
      }
    }
  };
</script>

<style scoped>
    .box-card {
        margin: 10px 0;
    }
</style>
