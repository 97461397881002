<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>账号设置</el-breadcrumb-item>
            <el-breadcrumb-item>修改密码</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card shadow="never" class="box-card">
            <h4>修改密码</h4>
            <div class="create-form-wrapper">
                <el-form ref="pwdForm" label-position="left" label-width="120px" :model="pwdFormData" :rules="rules">
                    <el-form-item label="旧密码" prop="password">
                        <el-input placeholder=""
                                  v-model="pwdFormData.password"
                                  show-password
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newPassword">
                        <el-input placeholder=""
                                  v-model="pwdFormData.newPassword"
                                  show-password
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="确认新密码" prop="reNewPassword">
                        <el-input placeholder=""
                                  v-model="pwdFormData.reNewPassword"
                                  show-password
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-loading="isChanging" @click="changePwd">确认修改</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import fetch from '../../utils/fetch';
  import { baseUrl } from '../../utils/env';
  export default {
    name: 'PasswordManage',
    data() {
      return {
        pwdFormData: {
          password: '',
          newPassword: '',
          reNewPassword: ''
        },
        rules: {
          password: [
            { required: true, message: '请输入旧密码', trigger: 'blur' }
          ],
          newPassword: [
            { required: true, message: '请输入新密码', trigger: 'blur' }
          ],
          reNewPassword: [
            { required: true, message: '请再输入一次新密码', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (value !== this.pwdFormData.newPassword) {
                  callback('请确保两次输入的新密码一致')
                } else {
                  callback();
                }
              },
              trigger: 'blur'
            }
          ],
        },
        isChanging: false
      }
    },
    methods: {
      ...mapActions([
        'loginOut'
      ]),
      changePwd() {
        this.$refs['pwdForm'].validate(async (isValid) => {
          if (!isValid) return;
          this.isChanging = true;
          const { password, newPassword } = this.pwdFormData;
          try {
            const res = await fetch(`${baseUrl}/api/agent/changePwd`, {
              password,
              newPassword
            }, 'POST');
            if (res.code === 200) {
              this.$message({
                message: '修改成功，请重新登录',
                type: 'success'
              });
              this.loginOut();
            } else {
              this.$message({
                message: `修改失败：${res.message}`,
                type: 'error'
              });
            }
          } catch (e) {
            this.$message({
              message: `修改失败：${e.message}`,
              type: 'error'
            });
          }
          this.isChanging = false;
        });
      }
    }
  };
</script>

<style scoped>
    .box-card {
        margin: 10px 0;
    }
    .create-form-wrapper {
        width: 30%;
        margin: 10px auto 0;
    }
</style>
