<template>
    <el-card shadow="never" class="box-card">
        <h4>创建工单</h4>
        <div style="margin: 0 auto;width: 360px;margin-top: 20px;">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="节点类型" prop="node_type">
                    <el-select style="width: 100%;" v-model="form.node_type" @change="changeNodeType"
                        placeholder="请选择节点类型">
                        <el-option v-for="item in nodeTypeList" :label="item.label" :value="item.value"
                            :disabled="item.disabled">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="工单类型" prop="typeId">
                    <el-cascader style="width: 100%;" ref="cascaderRef" v-model="form.typeId" :options="orderTypeList"
                        :props="{ expandTrigger: 'hover', value:'Id',label:'Name',children:'Children' }"
                        @change="changeOrderType"></el-cascader>
                </el-form-item>
                <el-form-item label="所属账号" prop="account">
                    <el-input v-model="form.account" placeholder="请输入要提工单的邮箱"></el-input>
                </el-form-item>
                <el-form-item v-if="isHadHostname" label="主机名" prop="server_name">
                    <el-input v-model="form.server_name" placeholder="请输入主机名"></el-input>
                </el-form-item>
                <el-form-item v-if="isHadServerID" label="设备ID" prop="server_id">
                    <el-input v-model="form.server_id" placeholder="请输入设备ID"></el-input>
                </el-form-item>
                <el-form-item v-if="isTask" label="目标任务" prop="task_id">
                    <el-select style="width: 100%;" v-model="form.task_id" placeholder="请选择目标任务">
                        <el-option v-for="item in orderTaskList" :label="item.Name" :value="item.Id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="isHadMac" label="mac地址" prop="mac_address">
                    <el-input type="textarea" v-model="form.mac_address" placeholder="请每行输入一个mac地址"></el-input>
                </el-form-item>
                <el-form-item label="问题描述" prop="problem_desc">
                    <el-input type="textarea" v-model="form.problem_desc" placeholder="请输入问题描述"></el-input>
                </el-form-item>
                <el-form-item v-if="isOffline" :label="reasonType" prop="reason">
                    <el-input v-model="form.reason" placeholder="请输入原因"></el-input>
                </el-form-item>
                <el-form-item label="故障截图" prop="image_urls">
                    <el-upload ref="uploadRef" class="upload-demo" :action="uploadUrl" name="image"
                        :file-list="fileList" :data="uploadData" :headers="headers" accept=".jpg,.jpeg,.png" :limit="4"
                        :on-success="successUpload" :on-exceed="handleExceed" drag multiple show-file-list>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit" :loading="loading">立即创建</el-button>
                    <el-button @click="onCancel">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-card>
</template>

<script>
    import { rules, nodeTypeList, validateMac } from '@/utils/orderLists.js';
    import { baseUrl } from '../../utils/env';
    import fetch from '../../utils/fetch';

    // const uploadUrl = "https://api-test.applet.bonuscloud.work/crm/uploadImage";
    const uploadUrl = "https://api.applet.bonuscloud.work/crm/uploadImage";

    export default {
        data() {
            return {
                form: {
                    typeId: '',
                    first_cate_id: '',
                    second_cate_id: '',
                    account: '',
                    server_id: '',
                    task_id: '',
                    problem_desc: '',
                    reason: '',
                    image_urls: '',
                    node_type: '',
                    server_name: '',
                    mac_address: '',
                },
                rules,
                nodeTypeList,
                orderTypeList: [],
                orderTaskList: [],
                accountList: [],
                hostNameList: [],
                isHadServerID: false,
                isHadHostname: false,
                isOffline: false,
                isHadAuth: false,
                isHadMac: false,
                isTask: false,
                selectNodeType: '',
                selectImgList: [],
                filePathsList: [],
                fileList: [],
                reasonType: '',
                uploadUrl,
                headers: {
                    "token": "r0lGF4aEgAp+U16oigtrppFJE5brEQQplqBwG9O1dL4="
                },
                uploadData: {
                    type: 1,
                },
                loading: false
            }
        },
        methods: {
            async getOrderList(type) {
                const res = await fetch(baseUrl + '/api/order/getWorkOrderTypeList')
                this.orderTypeList = res.code == 0 ? res.data : [];
                this.orderTypeList.forEach(item => {
                    if (!item.Children.length) {
                        delete item.Children;
                    }
                });
            },
            async getTaskList() {
                const res = await fetch(baseUrl + '/api/order/getWorkOrderTaskList')
                this.orderTaskList = res.code == 0 ? res.data : [];
            },
            changeNodeType(val) {
                this.selectNodeType = val;
                this.clearPage(1);
                val == 1 ? this.getOrderList(1) : this.getOrderList(2);
            },
            changeOrderType(val) {
                this.clearPage(1);
                if (this.selectNodeType == 1) {
                    // 标准节点页面
                    if (val[0] == 2) {
                        // 任务类 部署任务、申请验收只需要serverID 切换任务只需要serverHostName
                        if (val[1] == 9 || val[1] == 19) {
                            this.isHadServerID = true;
                            this.isHadHostname = false;
                        } else if (val[1] == 10) {
                            this.isHadServerID = false;
                            this.isHadHostname = true
                        }
                    } else if (val[0] == 1) {
                        // 咨询类 账单咨询需要主机名；技术咨询、任务咨询都不需要
                        this.isHadHostname = val[1] == 7 ? true : false;
                        this.isHadServerID = false;
                    } else {
                        // 其余类别全部只需serverhostname
                        this.isHadHostname = true;
                        this.isHadServerID = false;
                    }

                    // 任务类 额外需要增加目标任务选择
                    this.isTask = val[0] == 2 ? true : false;

                    // 下线类 额外需要增加下线原因；任务类 切换任务需要切换原因
                    if (val[0] == 5) {
                        this.isOffline = true;
                        this.reasonType = '下线原因'
                    } else if (val[0] == 2 && val[1] == 10) {
                        this.isOffline = true;
                        this.reasonType = '切换原因'
                    } else {
                        this.isOffline = false;
                    }
                } else {
                    // 轻节点页面 计算任务、网络任务需要mac地址
                    this.isHadMac = val[0] == 101 || val[0] == 102 ? true : false;
                }
            },
            successUpload(response, file, fileList) {
                if (response.code == 0) {
                    this.filePathsList = fileList;
                }
            },
            handleExceed() {
                this.$message.warning(`限制选择 4 个文件`);
            },
            onSubmit() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        this.loading = true;
                        this.form.first_cate_id = this.form.typeId[0];
                        this.form.second_cate_id = this.form.typeId.length == 2 ? this.form.typeId[1] : '';
                        this.form.account = this.form.account.trim();
                        this.form.server_name = this.form.server_name.trim();
                        this.form.server_id = this.form.server_id.trim();
                        this.form.mac_address = this.form.mac_address.trim();

                        let list = [];
                        this.filePathsList.forEach(item => {
                            const { ImageUrl } = item.response.data;
                            list.push(ImageUrl);
                        })
                        this.form.image_urls = list.join(',');
                        let macList = this.form.mac_address.split(/[(\r\n)\r\n]+/);
                        this.form.mac_address = macList.join(',');

                        const res = await fetch(baseUrl + '/api/order/submitOrder', this.form, 'POST');
                        this.loading = false;
                        if (res.code == 0) {
                            this.$message.success('创建成功');
                            this.clearPage();
                            this.$refs.form.resetFields();
                            this.$emit('clickBrotherBtn')
                        } else {
                            let stringVal = '';
                            macList.map((item, index) => {
                                let newItem = index == macList.length - 1 ? item : item + '\n';
                                stringVal += newItem;
                            })
                            this.form.mac_address = stringVal;
                            this.$message.error(res.msg || '创建失败，请重试'  );
                        }
                    }
                });
            },
            onCancel() {
                this.clearPage();
                this.$refs.form.resetFields();
            },
            clearPage(val) {
                if (!val) {
                    this.form = {
                        typeId: [],
                        first_cate_id: '',
                        second_cate_id: '',
                        account: '',
                        server_id: '',
                        task_id: '',
                        problem_desc: '',
                        reason: '',
                        image_urls: '',
                        node_type: '',
                        server_name: '',
                        mac_address: '',
                    }
                } else {
                    this.typeId = [];
                    this.form.first_cate_id = '';
                    this.form.second_cate_id = '';
                    this.form.account = '';
                    this.form.server_id = '';
                    this.form.task_id = '';
                    this.form.problem_desc = '';
                    this.form.reason = '';
                    this.form.image_urls = '';
                    this.form.server_name = '';
                    this.form.mac_address = '';
                }
                this.isHadHostname = false;
                this.isHadServerID = false;
                this.isTask = false;
                this.isHadMac = false;
                this.isOffline = false;
                this.$refs.uploadRef.clearFiles();
                // 以下为清空cascader element未升级的处理方法
                this.$refs.cascaderRef.$refs.panel.clearCheckedNodes()
                this.$refs.cascaderRef.$refs.panel.activePath = []
                this.$refs.cascaderRef.$refs.panel.scrollIntoView()
                this.$refs.cascaderRef.$refs.input.$refs.input.setAttribute('aria-expanded', false)
                this.$refs.cascaderRef.$emit('visible-change', false)
                this.$refs.cascaderRef.$refs.panel.$emit('visible-change', false)
                this.$refs.cascaderRef.$refs.panel.$emit('close')
            }
        },
        created() {
            this.getTaskList();
        }
    }

</script>

<style>

</style>