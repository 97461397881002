<template>
  <!--  该功能只适用于子账号  -->
  <div v-if="isFather" style="padding: 20px; text-align: center">无权限</div>
  <div v-else class="home hardlist-home hard-wrap dial-home">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>资源管理</el-breadcrumb-item>
      <el-breadcrumb-item>矿机管理</el-breadcrumb-item>
      <el-breadcrumb-item>拨号配置管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div
        class="button dail-button"
        @click="showAddConfig = true; commitType = 'ADD'; dialConfigs = []"
    >添加配置</div>
    <el-dialog
        title="汇聚拨号配置"
        width="60%"
        :visible.sync="showAddConfig"
    >
      <div class="dial-config-dialog">
        <div class="dial-config-item">
          <span class="template-title ">配置名称</span>
          <el-input
                  v-model="dialConfigName"
                  class="config-name margin-left-20"
                  style="width:200px"
          ></el-input>
        </div>
        <div class="dial-config-item">
          <span class="template-title ">拨号类型</span>
          <!-- <el-input
            v-model="dialConfigName"
            class="config-name margin-left-20"
          ></el-input> -->
          <el-select
                  class="dial-input margin-left-20"
                  v-model="dialConfigType"
                  placeholder="请选择"
                  @change="changeDialConfigType"
          >
            <el-option
                    label="动态IP汇聚"
                    value="0"
            >
            </el-option>
            <el-option
                    label="静态IP/内网IP汇聚"
                    value="1"
            >
            </el-option>
          </el-select>
        </div>

        <div class="dial-config-item">
          <div class="template-title">模版导入</div>
          <el-alert
                  title="请先下载Excel模板，并按模板格式填写并上传"
                  type="error"
          >
          </el-alert>
          <!-- <NoLineTips
            :tips="$t('tips')"
            class="dial-config-tips"
          /> -->
          <div class="template-wrap">
            <div class="import-template">
              <!-- <div class="bonus-tips"></div>  -->
              <div class="dial-tips-item">
                <div class="dial-tips-upload">
                  <span class="each-item bonus-over-ellip">模版下载</span>
                  <a
                    :href=" dialConfigType == 0 ? './../../汇聚拨号线路提交模版.xlsx' : './../../汇聚拨号线路提交模版2.xlsx' "
                    download
                    class="each-item button"
                  >下载</a>
                </div>

              </div>
              <div class="dial-tips-item">
                <div class="dial-tips-upload">
                  <span class="each-item bonus-over-ellip" style="position: relative; top: -13px;">导入模版</span>
                  <!-- <button class="each-item button">选择</button> -->
                  <el-upload
                          ref="upload"
                          action="/"
                          :show-file-list="false"
                          :on-change="importExcel"
                          :auto-upload="false"
                          class="each-item tempalte-upload-button"
                  >
                    <div
                        class="button"
                        type="primary"
                    >
                      选择
                    </div>
                  </el-upload>
                </div>
              </div>
            </div>
            <NoLineTips
                :tips="tips"
                class="dial-config-tips"
            />
          </div>

        </div>
        <div class="dial-config-item">
          <div class="template-title dial-write-title">手动填写</div>
          <el-table
            :data="dialConfigs"
            align="center"
          >
            <!-- 序号 -->
            <el-table-column
              type="index"
              width="50"
              label="序号"
            >
            </el-table-column>
            <!-- 单线带宽大小 -->
            <el-table-column label="单线带宽大小">
              <template slot-scope="scope">
                <el-input
                        v-model="scope.row.mbps"
                        placeholder="单位：Mbps"
                        type='number'
                ></el-input>
              </template>
            </el-table-column>
            <!-- 拨号账号 -->
            <el-table-column
                    label="拨号账号"
                    v-if="dialConfigType == 0"
                    min-width="140px"
                    align="center"
            >
              <template slot-scope="scope">
                <el-input v-model="scope.row.account"></el-input>
              </template>
            </el-table-column>
            <!-- 拨号密码 -->
            <el-table-column label="拨号密码" v-if="dialConfigType == 0">
              <template slot-scope="scope">
                <el-input v-model="scope.row.password"></el-input>
              </template>
            </el-table-column>
            <!-- IP -->
            <el-table-column label="IP" v-if="dialConfigType == 1">
              <template slot-scope="scope">
                <el-input v-model="scope.row.ip"></el-input>
              </template>
            </el-table-column>
            <!-- 网关 -->
            <el-table-column label="网关" v-if="dialConfigType == 1">
              <template slot-scope="scope">
                <el-input v-model="scope.row.gateway"></el-input>
              </template>
            </el-table-column>
            <!-- 网段 -->
            <el-table-column label="网段" v-if="dialConfigType == 1">
              <template slot-scope="scope">
                <el-input v-model="scope.row.segment"></el-input>
              </template>
            </el-table-column>
            <!-- VLAN端口 -->
            <el-table-column label="VLAN端口">
              <template slot-scope="scope">
                <el-input
                        v-model="scope.row.vlan1"
                ></el-input>
              </template>
            </el-table-column>
            <!-- 多拨次数 -->
            <el-table-column label="多拨次数">
              <template slot-scope="scope">
                <el-input
                        v-model="scope.row.count"
                        placeholder="单位：次"
                        type='number'
                        min="1"
                ></el-input>
              </template>
            </el-table-column>
            <!-- 绑定网卡 -->
            <el-table-column label="绑定网卡Mac">
              <template slot-scope="scope">
                <el-input
                        v-model="scope.row.mac"
                        placeholder="选填"
                ></el-input>
              </template>
            </el-table-column>
            <!-- 网卡名 -->
            <el-table-column label="网卡名">
              <template slot-scope="scope">
                <el-input
                        v-model="scope.row.nic"
                        placeholder="选填"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="删除">
              <template slot-scope="scope">
               <div
                 class="sure-unbind red-button margin-10"
                 style="width: 50px; text-align: center;"
                 type="primary"
                 @click="delDial(scope)"
               >删除</div>
              </template>
            </el-table-column>
          </el-table>
          <div class="add-config-wrap">
            <div
                class="button dial-add-line"
                @click="addConfig"
            >添加行</div>
            <div
                class="button dial-add-line"
                @click="resetConfig"
            >清空</div>
          </div>
        </div>
        <div
          slot="footer"
          class="dialog-footer dial-config-footer node-button-wrap "
        >
          <div
            class="sure-unbind red-button margin-10"
            type="primary"
            @click="showAddConfig = false"
          >取消</div>
          <div
            class="sure-unbind button margin-10"
            @click="clickDialConfig"
          >确定</div>
        </div>
      </div>

    </el-dialog>
    <el-table
        v-if="dialConfig !== 'NODATA'"
        align="left"
        :data="dialConfig"
        empty-text="loading..."
    >
      <!-- 配置名称 -->
      <el-table-column
        prop="dialConfigName"
        label="配置名称"
        align='center'
      >
      </el-table-column>
      <!-- 汇聚线路数量 -->
      <el-table-column
        prop="dialCount"
        label="汇聚线路数量"
        align='center'
      >
      </el-table-column>
      <!-- 汇聚总带宽 -->
      <el-table-column
        label="汇聚总带宽"
        align='center'
      >
        <template slot-scope="scope">
          <div>{{ scope.row.dialBandwidths }} Mbps</div>
        </template>
      </el-table-column>
      <!-- 配置状态 -->
      <el-table-column
        label="配置状态"
        align='center'
      >
        <template slot-scope="scope">
          <div v-if="scope.row.dialStatus == 0">配置中</div>
          <div v-if="scope.row.dialStatus == 1">已绑定设备</div>
        </template>
      </el-table-column>

      <el-table-column align='center'>
        <template slot-scope="scope">
          <div
            class="operation"
          >
            <!-- 编辑 -->
            <div
              type="danger"
              class="button"
              :deviceId="scope.row.dialConfigID"
              @click="sureEditConfig(scope.row)"
            >编辑</div>
            <!-- restart -->
            <div
              type="danger"
              class="red-button"
              v-if="scope.row.dialStatus == 0"
              @click="sureDeleteConfig(scope.row)"
            >删除</div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-table
      v-if="dialConfig === 'NODATA'"
      align="left"
      empty-text="无数据"
      style="width: 100%"
    >
    </el-table>

    <!-- 删除 -->
    <el-dialog
      title="提示"
      :visible.sync="showDeleteDialog"
    >
      <span>确定删除 {{dealDialName}} 吗</span>
      <span
              slot="footer"
              class="dialog-footer"
      >
          <el-button @click="showDeleteDialog = false">取 消</el-button>
          <el-button
                  type="primary"
                  @click="deleteDial"
          >确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import NoLineTips from "@/components/Common/NoLineTips.vue";
import XLSX from "xlsx";
import fetch from '../../utils/fetch';
import { baseUrl } from '../../utils/env';

export default {
  name: "DialConfig",
  components: {
    NoLineTips
  },
  data() {
    return {
      showAddConfig: false,
      dialConfigName: "", //配置名称
      dialConfigs: [
        {
          mbps: "",
          account: "",
          password: "",
          vlan1: "",
          count: "",
          segment: "",
          ip: ""
        }
      ],
      dealDialId: "",
      dealDialName: "",
      showDeleteDialog: false,
      commitType: "",
      dealCIID: '', //cmbd强制要求删除时需要用ciid删除
      commitStatus: false, //提交状态，避免重复提交
      dialConfigType: "0",
      firstTips: [
        "模板要求:",
        "1.单线带宽大小，账号，密码为必填，VLAN，多拨次数，绑定网卡Mac，网卡名为选填。",
        "2.单线带宽大小，VLAN端口，多拨次数只可填写数字，否则无法识别，账号前有0仔细确认填写完整。",
        "3.单台设备最多汇聚100条线路，总带宽5G，请合理分配。"
      ],
      secondTips: [
        "模板要求:",
        "1. 单线带宽大小，IP，网关，网段为必填，VLAN，绑定网卡Mac，网卡名为选填。",
        "2. 单线带宽大小只可填写数字，IP，网关只可填写 IP地址，否则无法识别。",
        "3. 网段填写示例：111.12.13.0/24，填写其他内容无效。",
        "4. 单台设备最多汇聚100条线路，总带宽5G，请合理分配。"
      ],
      dialConfig: []
    };
  },
  computed: mapState({
    tips () {
      return (this.dialConfigType == 0 ? this.firstTips : this.secondTips)
    },
    isFather: state => !state.accountInfo.level
  }),
  methods: {
    // 导入excel
    importExcel(file) {
      const types = file.name.split(".")[1];
      const fileType = ["xlsx"].some(item => item === types);
      if (!fileType) {
        this.$message({
          type: "error",
          message: "格式错误！请重新选择"
        });
        return;
      }
      this.file2Xce(file).then(tabJson => {
        if (tabJson && tabJson.length > 0) {
          this.dialConfigs = tabJson[0].sheet.map(item => {
            return {
              mbps: item["单线带宽大小"],
              account: item["拨号账号"],
              segment: item["网段"],
              gateway: item["网关"],
              ip: item["IP"],
              password: item["拨号密码"],
              vlan1: item["VLAN端口"],
              count: item["多拨次数"],
              mac: item["绑定网卡Mac"],
              nic: item["网卡名"],
            };
          });
        }
      });
    },
    file2Xce(file) {
      return new Promise(function(resolve, reject) {
        const reader = new FileReader();
        reader.onload = function(e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: "binary"
          });
          const result = [];
          this.wb.SheetNames.forEach(sheetName => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            });
          });
          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
        // reader.readAsBinaryString(file) // 传统input方法
      });
    },
    // 添加拨号配置
    addConfig() {
      this.dialConfigs.push({
        mbps: "",
        account: "",
        password: "",
        vlan1: "",
        count: "",
        segment: "",
        gateway: "",
        ip: "",
      });
    },
    resetConfig() {
      this.dialConfigs = [];
    },
    //删除拨号配置
    getRowKey (row) {
      return row.id
    },
    delDial(scope){
      this.dialConfigs.splice(scope.$index,1);
    },
    //提交拨号配置
    clickDialConfig() {
      let that = this;
      let {
        dialConfigs,
        dialConfigName,
        commitType,
        dealDialId,
        commitStatus,
        dialConfigType
      } = this;
      if (commitStatus) {
         return this.$message({
          message: "正在提交中...",
          type: "error"
        });
      }

      if (!dialConfigName) {
        return this.$message({
          message: "请填写配置名称",
          type: "error"
        });
      }

      let alreadyConfiged = true;
      dialConfigs.map((item, index) => {

        if (dialConfigType == 0) {
          if (!item.account || !item.mbps || !item.password) {
            alreadyConfiged = false;
            return this.$message({
              type: "error",
              message: `第${index}行未填写`
            });
          }
        } else {
          if (!item.ip || !item.gateway || !item.segment) {
            alreadyConfiged = false;
            return this.$message({
              type: "error",
              message: `第${index}行未填写`
            });
          }
        }


        if (item.count && isNaN(item.count)) {
          return this.$message({
            type: "error",
            message: `第${index + 1}行多拨次数请填写数字`
          });
        }
      });

      if (alreadyConfiged) {
        const loading = this.$loading({
          lock: true,
          text: '保存中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });


        // 添加锁，避免重复点击
        this.commitStatus = true;
        fetch(baseUrl + '/api/standardNode/setDialConfig', {
          dialConfigs,
          dialConfigName,
          commitType,
          dealDialId,
          dialConfigType
        }, 'POST').then(res => {
          this.commitStatus = false;
          loading.close();
          if (res.status === 200) {
            this.$message({
              type: "success",
              message: `保存成功`
            });
            that.dialConfigs = [
              {
                mbps: "",
                account: "",
                password: "",
                vlan1: "",
                count: "",
                ip: "",
                gateway: "",
                segment: ""
              }
            ];
            that.dialConfigName = "";
            that.dialConfigType = 0;
            that.showAddConfig = false;
            that.getDialConfig();
          } else {
            this.$message({
              message: res.message || "网络失败",
              type: "error"
            });
          }
        });
      }
    },
    sureDeleteConfig(dialItem) {
      this.dealDialName = dialItem.dialConfigName;
      this.dealCIID = dialItem._id;
      this.dealDialId = dialItem.dialConfigID;
      this.showDeleteDialog = true;
    },
    // 删除配置
    deleteDial() {
      console.log({ dialConfigID: this.dealDialId, CIID: this.dealCIID })
      // 显示loading 防止多次操作
      const loading = this.$loading({
        lock: true,
        text: 'loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      fetch(baseUrl + '/api/standardNode/deleteDialConfig', {
        dialConfigID: this.dealDialId,
        CIID: this.dealCIID
      }, 'POST').then(res => {

        loading.close();

        if (res.status === 200) {
          this.$message({
            type: "success",
            message: `删除成功`
          });
          this.getDialConfig();
          this.showDeleteDialog = false;

        } else {
          this.$message({
            message: res.message || '网络问题，请重试',
            type: "error"
          });
        }
      });
    },
    //编辑配置
    sureEditConfig(dialItem) {
      this.dealDialName = dialItem.dialConfigName;
      this.dealDialId = dialItem.dialConfigID;

      this.showAddConfig = true;
      this.dialConfigName = dialItem.dialConfigName;
      this.dialConfigType = dialItem.dialConfigType;
      this.commitType = "EDIT";
      this.dialConfigs = dialItem.dialAccounts;
    },
    changeDialConfigType() {
      this.dialConfigs = [{
        mbps: "",
        account: "",
        password: "",
        vlan1: "",
        count: "",
        segment: "",
        ip: "",
        gateway: ""
      }];
    },
    async getDialConfig(data) {
      const res = await fetch(`${baseUrl}/api/standardNode/getDialConfig`, data);
      if (res.data && res.data.length) {
        this.dialConfig = res.data;
      } else if (data && data.configed) {
        this.dialConfig = [];
      } else {
        this.dialConfig = "NODATA";
      }
    }
  },
  async created() {
    this.getDialConfig();
  }
};
</script>

<style lang="stylus">
.dail-button {
  margin: 20px 0;
}

.add-config {
  position: fixed;
  z-index: 1000;
  width: 80%;
  margin: 20px;
}

.config-name.el-input {
  max-width: 300px;
}

.template-wrap {
  display: flex;

  .import-template{
    width: 40%;
    // padding: 20px;
    padding-top: 20px;
  }
  .dial-config-tips {
    width: 60%;
    // padding: 20px;
  }
}

.template-title {
  margin-bottom: 20px;
  font-size: 20px;
}

.dial-tips-item {
  display: flex;

  .each-item {
    margin-right: 20px;
    margin-top: 20px;
  }

  span.each-item {
    line-height: 30px;
  }
}

.dial-config-item {
  margin-top: 20px;
  padding: 10px;
}

.add-config-wrap {
  text-align: center;
  margin: 20px;
}


.dial-config-dialog {
  margin: 10px 20px;
  text-align: left;
}

.dial-config-footer {
  margin-top: 40px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;
}

.dial-write-title {
  margin-top: 40px;
  margin-bottom: 50px;
}

.tempalte-upload-button{
  display: inline-block;
  margin-left: 10px;

  .el-upload {
    border: 0;
    border-radius: 0;
  }

  .button {
    margin: 0;
  }
}

.dial-tips-upload{
  margin: 10px auto;
}

.dial-home .tips-wrap {
  width: 100%;
  margin-top: 20px;
}

.dial-add-line{
  margin: 10px
}
</style>
