import {
  LOGIN_INFO,
} from './mutation-types';

export default {
  // 登录后暂存账户信息
  [LOGIN_INFO](state, account) {
    state.accountInfo = Object.assign({}, account);
  },
};
