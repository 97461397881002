// action
import fetch from '../utils/fetch';
import { baseUrl } from '../utils/env';
import {
  LOGIN_INFO,
} from './mutation-types';
import router from '../router';

export default {
  async login({ commit }, params) {
    const res = await fetch(`${baseUrl}/api/agent/login`, params, 'POST');
    if (res.code === 200) {
      commit(LOGIN_INFO, res.data);
    } else {
      commit(LOGIN_INFO, {});
    }
    return res;
  },
  // 根据sessionId查询用户信息
  async getAgentUserInfo({ commit }) {
    const res = await fetch(`${baseUrl}/api/agent/getAgentUserInfo`);
    if (res.code === 200) {
      commit(LOGIN_INFO, res.data);
    } else {
      commit(LOGIN_INFO, {});
      router.push({ name: 'login' });
    }
    return res;
  },
  async loginOut({ commit }) {
    const res = await fetch(`${baseUrl}/api/agent/loginOut`);
    if (res.code === 200) {
      commit(LOGIN_INFO, {});
      router.push({ name: 'login' });
    }
    return res;
  },
};

