<template>
  <div class="account-set-layout">
    <Header type='home-header' />
    <div
      v-show="showMenu"
      class="layer"
      @click="toggleShowMenu"
    ></div>
    <el-row class="tac">
      <el-col
        :span="4"
        class="menu-el-col menu"
        :class="{show: showMenu}"
      > 
        <!-- 数据面板 -->
        <h5 class="panel-wrap"  :class="{none: !showMenu}">{{ $t('menu.dataPanel')}}</h5>
        <el-menu
          :class="{none: !showMenu}"
          class="bonus-menu"
          background-color="#0A0E22"
          active-text-color="#fff"
          text-color="#989AA2"
          router="router"
        >
          <!-- 数据面板 -->
          <el-menu-item
            index="0"
            route="/"
            class="home-page-wrap"
            v-bind:class="{ isActive: (thisPath === '/') }"
          >
            <img
              class="menu-icon home-icon"
              src="../../assets/menu/homeIcon.png"
            >
            <span slot="title">{{ $t('menu.dataPanel')}}</span>
          </el-menu-item>
          <!-- 轻节点 -->
          <el-submenu index="1">
            <template slot="title">
              <img
                class="menu-icon home-icon total-revenue-icon"
                src="../../assets/menu/liteNode.png"
              >
              <span>{{ $t('menu.liteNode')}}</span>
            </template>
            <!-- <el-submenu index="1-1">
            <template slot="title">选项4</template>
            <el-menu-item index="1-4-1">选项1</el-menu-item>
          </el-submenu> -->
            <!-- 收益详情 -->
            <el-submenu index="1-1">
              <template slot="title">
                <div v-bind:class="{ revenuePad: true }">
                  <img
                    class="menu-icon home-icon total-revenue-icon"
                    src="../../assets/menu/totalRevenue.png"
                  >
                  <span>{{ $t('menu.revenueDetails')}}</span>
                </div>
              </template>
              <!-- 收益总览 -->
              <el-menu-item
                index="1-1-1"
                route="/revenue"
                v-bind:class="{ isActive: (thisPath === '/revenue') }"
              >{{
              $t('menu.revenueOverview') }}
              </el-menu-item>
              <!-- 设备收益详情 -->
              <el-menu-item
                index="1-1-2"
                route="/devAllRevenue"
                v-bind:class="{ isActive: (thisPath === '/devAllRevenue') }"
              >{{
              $t('menu.devTotalRevenue') }}
              </el-menu-item>
              <!-- 网络任务收益详情 -->
              <el-menu-item
                index="1-1-3"
                route="/netDetail"
                v-bind:class="{ isActive: (thisPath === '/netDetail') }"
              >{{
              $t('menu.netRevenueDetails') }}
              </el-menu-item>
              <!-- 计算任务收益详情 -->
              <el-menu-item
                index="1-1-4"
                route="/calculateDetail"
                v-bind:class="{ isActive: (thisPath === '/calculateDetail') }"
              >{{$t('menu.calRevenueDetails') }}
              </el-menu-item>
              <!-- 邀请奖励 -->
              <el-menu-item
                index="1-1-5"
                route="/inviteDetail"
                v-bind:class="{ isActive: (thisPath === '/inviteDetail') }"
              >{{$t('menu.inviteDetail') }}
              </el-menu-item>

            </el-submenu>
            <el-submenu index="1-2">
              <template slot="title">
                <div v-bind:class="{ revenuePad: true }">
                  <img
                    class="menu-icon home-icon total-revenue-icon"
                    src="../../assets/menu/network.png"
                  >
                  <span>{{ $t('menu.taskStatus')}}</span>
                </div>
              </template>
              <!-- 设备总览 -->
              <el-menu-item
                index="2-1"
                route="/deviceOverview"
                v-bind:class="{ isActive: (thisPath === '/deviceOverview') }"
              >{{$t('menu.deviceOverview') }}
              </el-menu-item>
              <!-- 网络任务运行状态 -->
              <el-menu-item
                index="2-2"
                route="/netStatus"
                v-bind:class="{ isActive: (thisPath === '/netStatus') }"
              >{{$t('menu.networkTaskStatus') }}
              </el-menu-item>
              <!-- 计算任务运行状态 -->
              <el-menu-item
                index="2-3"
                route="/calculateStatus"
                v-bind:class="{ isActive: (thisPath === '/calculateStatus') }"
              >{{$t('menu.calTaskStatus') }}
              </el-menu-item>
              <!-- 计算任务选择 -->
              <el-menu-item
                index="2-4"
                route="/calculateTask"
                v-bind:class="{ isActive: (thisPath === '/calculateTask') }"
              >{{$t('menu.calTask') }}
              </el-menu-item>
            </el-submenu>
          </el-submenu>
          <!-- 标准节点-->
          <el-submenu
            v-if="isZnWebsite"
            index="9"
            class="revenue-wrap"
          >
            <template slot="title">
              <div v-bind:class="{ revenuePad: true }">
                <img
                  class="menu-icon home-icon total-revenue-icon"
                  src="../../assets/menu/standNode.png"
                >
                <span>{{$t('menu.standardNode')}}</span>
              </div>
            </template>
            <el-menu-item-group :span="1">
              <!-- 节点运行状态 -->
              <el-menu-item
                index="9-1"
                route="/standardStatus"
                v-bind:class="{ isActive: (thisPath === '/standardStatus') }"
              >
                {{$t('menu.nodeRunStatus') }}
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group :span="2">
              <!-- 节点运行状态 -->
              <el-menu-item
                index="9-2"
                route="/standardWatch"
                v-bind:class="{ isActive: (thisPath === '/standardWatch') }"
              >
                {{$t('watch') }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- 软路由-->
          <el-submenu
            v-if="isZnWebsite"
            index="6"
            class="revenue-wrap"
          >
            <template slot="title">
              <div v-bind:class="{ revenuePad: true }">
                <img
                  class="menu-icon home-icon total-revenue-icon"
                  src="../../assets/menu/dialStatus.png"
                >
                <span>软路由</span>
              </div>
            </template>
            <el-menu-item-group :span="2">
              <!-- 拨号状态 -->
              <el-menu-item
                index="2-1"
                route="/dialStatus"
                v-bind:class="{ isActive: (thisPath === '/dialStatus') }"
              >拨号状态
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- 提现 -->
          <el-menu-item
            index="4"
            v-if="!isZnWebsite"
            route="/withdrawal"
            v-bind:class="{ isActive: ifWithdrawal }"
          >
            <img
              class="menu-icon home-icon"
              src="../../assets/menu/withdrawal.png"
            >
            <span slot="title">{{ $t('menu.withdrawal') }}</span>
          </el-menu-item>
          <!-- 京东卡兑换 -->
          <el-menu-item
            index="4"
            v-if="isZnWebsite"
            route="/exchange"
          >
            <img
              class="menu-icon home-icon"
              src="../../assets/menu/withdrawal.png"
            >
            <span slot="title">{{ $t('menu.exchange') }}</span>
          </el-menu-item>
          <!-- 回购 -->
          <!-- <el-menu-item index="7" v-if="auth" route="/buyback" v-bind:class="{ isActive: ifBuyback }"> -->
          <el-menu-item
            index="8"
            v-if="isZnWebsite"
            route="/buyback"
          >
            <img
              class="menu-icon home-icon"
              src="../../assets/menu/withdrawal.png"
            >
            <span slot="title">{{ $t('menu.buyBack') }}</span>
          </el-menu-item>
          <!-- 回购95折 -->
          <!-- <el-menu-item
            index="9"
            v-if="isZnWebsite && auth"
            route="/buyBackSecond"
            v-bind:class="{ isActive: ifBuybackSecond }"
          >
            <img
              class="menu-icon home-icon"
              src="../../assets/menu/withdrawal.png"
            >
            <span slot="title">{{ $t('menu.buyBackSecond') }}</span>
          </el-menu-item> -->
          <!-- 全网贡献 -->
          <el-menu-item
            index="5"
            route="/networkContribution"
            v-bind:class="{ isActive: (thisPath === '/networkContribution') }"
          >
            <img
              class="menu-icon home-icon"
              src="../../assets/menu/networkCbt.png"
            >
            <span slot="title">{{ $t('menu.netContribution') }}</span>
          </el-menu-item>
          <el-menu-item
            index="7"
            disabled
          >
            <img
              class="menu-icon home-icon"
              src="../../assets/menu/powerAnalysis.png"
            >
            <span slot="title">{{ $t('menu.powerAnalysis') }}</span>
          </el-menu-item>
        </el-menu>
        <!-- 账号设置 -->
        <h5 class="panel-wrap">{{$t('account_set_menu')}}</h5>
        <el-menu
          class="bonus-menu"
          background-color="#0A0E22"
          text-color="#989AA2"
          active-text-color="#fff"
          router="router"
        >
          <!-- 钱包绑定 -->
          <!-- <el-menu-item index="2" route="/bindWallet" v-bind:class="{ isActive: (thisPath === '/bindWallet') }">
            <img class="menu-icon home-icon" src="../../assets/accountSet/walletBind.png">
            <span slot="title">{{$t('account_set_2')}}</span>
          </el-menu-item> -->
          <el-menu-item
            index="1"
            route="/accountSet"
            class="home-page-wrap"
            v-bind:class="{ isActive: (thisPath === '/accountSet') }"
          >
            <img
              class="menu-icon home-icon"
              src="../../assets/accountSet/accountSet.png"
            >
            <span slot="title">{{$t('account_set_1')}}</span>
          </el-menu-item>
          <el-menu-item
            v-if="isZnWebsite"
            index="1"
            route="/contact"
            class="home-page-wrap"
            v-bind:class="{ isActive: (thisPath === '/contact') }"
          >
            <img
              class="menu-icon home-icon"
              src="../../assets/accountSet/contact.png"
            >
            <span slot="title">{{$t('account_set_3')}}</span>
          </el-menu-item>
          <el-menu-item
            v-if="isZnWebsite"
            index="1"
            route="/withdrawalAuto"
            class="home-page-wrap"
            v-bind:class="{ isActive: (thisPath === '/withdrawalAuto') }"
          >
            <img
              class="menu-icon home-icon"
              src="../../assets/accountSet/contact.png"
            >
            <span slot="title">{{$t('account_set_4')}}</span>
          </el-menu-item>
          <el-menu-item
            class="logout"
            index="8"
            @click="clickLogout"
          >
            <!-- <img class="menu-icon home-icon" src="../../assets/menu/networkCbt.png"> -->
            <span slot="title">{{ $t('header.logout') }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col
        :span="20"
        class="bonus-content"
      >
        <div class="title cantact-title">
          <img
            v-if="thisPath === '/bindWallet'"
            class="title-img"
            src="../../assets/accountSet/walletBlack.png"
          />
          <img
            v-if="thisPath === '/accountSet'"
            class="title-img"
            src="../../assets/accountSet/accountBlack.png"
          />
          {{layoutTitile}}
        </div>
        <div>
        </div>
        <slot></slot>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Common/Footer.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "AccountSetLayout",
  components: {
    Header,
    Footer,
  },
  computed: mapState({
    isZnWebsite: (state) => state.isZnWebsite,
    thisPath() {
      return this.$route.path;
    },
  }),
  data() {
    return {
      showMenu: false,
    };
  },
  props: {
    layoutType: "",
    layoutTitile: "",
  },
  methods: {
    ...mapActions(["logout"]),
    toggleShowMenu() {
      this.showMenu = !this.showMenu;
      const body = document.getElementsByTagName("body")[0];
      if (this.showMenu) {
        body.classList.add("overflow-hidden");
      } else {
        body.classList.remove("overflow-hidden");
      }
    },
    clickLogout() {
      this.logout();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus">
.isActive, .el-menu-item.is-active {
  background-color: #032031;
  border-left: 3px solid #FFFFFF;
  color: #fff !important;
}

.account-set-layout, .tac {
  height: 100%;
}

.is-mobile {
  .menu {
    display: none;
    position: fixed;
    right: 0;
    width: 220px;
    z-index: 10;
    height: calc(100% - 62px);
    overflow-y: auto;
    background: #0a0f22;

    &.show {
      display: block;
    }

    .panel-wrap {
      opacity: 1;
    }
  }

  .bonus-content {
    padding: 0;
    width: 100%;
    min-height: 0;
    overflow: auto;

    .first-title {
      display: none;
    }
  }

  .date-panel-layout {
    /deep/ .home-header {
      margin: 0;
      width: 100%;
      overflow: hidden;

      .header-panel {
        .exit, .lang {
          position: relative;
          left: 0;
          right: 0;
        }

        &:before, &:after {
          display: none;
        }
      }

      .header-login-panel {
        width: auto;
      }
    }
  }

  .layer {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
  }

  .logout, .menu-item-wrapper {
    display: block;
  }

  .item-wraps {
    display: block !important;
    margin: 0 !important;
  }

  .left-item {
    margin: 20px 0;
  }

  .right-item {
    margin: 0 !important;
    width: 100% !important;
  }
  .cantact-title{
    margin-left: 20px;
  }
}
</style>
