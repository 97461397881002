

<template>
  <div>
    <div class="Footer">
      <div class="line"></div>
      <div class="foot-link">
        <div class="foot-detail">
          <a class="foot-detail-title">BonusCloud</a>
          <a
            class="foot-detail-link"
            target="_blank"
            :href="$t('Whitepaper_link')"
          >{{ $t('Whitepaper') }}</a>
          <a
            class="foot-detail-link"
            target="_blank"
            href="https://bonuscloud.work/#highlight"
          >{{ $t('Highlight') }}</a>
          <a
            class="foot-detail-link"
            target="_blank"
            href="https://bonuscloud.work/#advantage"
          >{{ $t('Advantage') }}</a>
          <!-- <a
            class="foot-detail-link"
            target="_blank"
            href="https://bonuscloud.io/#team"
          >{{ $t('Member') }}</a>
          <a
            class="foot-detail-link"
            target="_blank"
            href="https://bonuscloud.io/#agency"
          >{{ $t('Partner') }}</a> -->
          <!-- <a
            class="foot-detail-link"
            target="_blank"
            href="https://bonuscloud.io/#token"
          >{{ $t('Token') }}</a> -->
          <!-- <a
            class="foot-detail-link"
            target="_blank"
            href="https://bonuscloud.io/#roadmap"
          >{{ $t('Roadmap') }}</a> -->
          <a
            class="foot-detail-link"
            target="_blank"
            href="https://bonuscloud.work/protocol"
          >{{ $t('Agreement') }}</a>
        </div>
        <div class="foot-detail">
          <a class="foot-detail-title">Data Panel</a>
          <a
            class="foot-detail-link"
            target="_blank"
            href="/"
          >{{ $t('Get_BCode') }}</a>
          <a
            class="foot-detail-link"
            target="_blank"
            href="/revenue"
          >{{ $t('Revenue_Overview') }}</a>
          <a
            class="foot-detail-link"
            target="_blank"
            href="/netStatus"
          >{{ $t('Network_Task') }}</a>
          <a
            class="foot-detail-link"
            target="_blank"
            href="/calculateStatus"
          >{{ $t('Computing_Task') }}</a>
          <!-- <a
            class="foot-detail-link"
            target="_blank"
            href="https://console.bonuscloud.io/withdrawal"
          >{{ $t('Withdrawal') }}</a> -->
        </div>
        <div class="foot-detail">
          <a class="foot-detail-title">My Hardware</a>
          <a
            class="foot-detail-link"
            target="_blank"
            href="/hardList"
          >{{ $t('Hardware_List') }}</a>
        </div>
        <div class="foot-detail">
          <a class="foot-detail-title">Resources And Help</a>
          <a
            class="foot-detail-link"
            target="_blank"
            :href="$t('Guide_link')"
          >{{ $t('Guide') }}</a>
          <a
            class="foot-detail-link"
            target="_blank"
            :href="$t('Tutorial_link')"
          >{{ $t('Tutorial') }}</a>
          <a
            class="foot-detail-link"
            target="_blank"
            href="https://bonuscloud.work/download"
          >{{ $t('Download') }}</a>
        </div>
        <div class="foot-detail">
          <a class="foot-detail-title">Contact Us</a>
          <a
            target="_blank"
            href="mailto:hi@bonuscloud.io"
            class="footer-detail-email"
          >
            <img src="../../assets/footer/email.png" />
            <span>hi@bonuscloud.io</span>
          </a>

          <a class="foot-detail-title tech-community">Technology Community</a>
          <a
            target="_blank"
            href="https://bonuscloud.club/"
            class="foot-detail-link footer-club community-content"
          >
            <img src="../../assets/footer/club.png" />
            <span>BonusCloud Club</span>
          </a>
          <a
            target="_blank"
            href="http://qm.qq.com/cgi-bin/qm/qr?k=OhcxFgudR67SwHzcPzT3IcqBclaA7XSl"
            class="foot-detail-link community-content"
          >
            <img src="../../assets/footer/qq.png" />
            <span>740788277 / 871444105</span>
          </a>
          <div class="community-content footer-tele">
            <img src="../../assets/footer/telegram.png" />
            <a href="https://t.me/Bonuscloud">EN</a>
            <span> / </span>
            <a href="https://t.me/bonuscloudcn">CN</a>
            <span> / </span>
            <a href="https://t.me/BonuscloudRu">RU</a>
          </div>
        </div>
      </div>
      <div class="user-protocol">
        <div>
          <a
            href="https://medium.com/@bonuscloud"
            class="foot-annouce-icon"
          >
            <img src="../../assets/footer/medium.png">
          </a>
          <a
            href="https://www.facebook.com/BonusCloud.io"
            class="foot-annouce-icon"
          >
            <img src="../../assets/footer/facebook.png">
          </a>
          <a
            href="https://twitter.com/bonus_cloud"
            class="foot-annouce-icon"
          >
            <img src="../../assets/footer/twitter.png">
          </a>
          <a
            href="https://www.youtube.com/channel/UCqVL-o6r_WFf3ZzavCZHWyg?view_as=subscriber"
            class="foot-annouce-icon"
          >
            <img src="../../assets/footer/youtube.png">
          </a>
          <a
            href="https://github.com/BonusCloud"
            class="foot-annouce-icon"
          >
            <img src="../../assets/footer/github.png">
          </a>
        </div>
        <div>
          闽ICP备17014081号-2  丨  
          <a
            class="footer-agree-link"
            target="_blank"
            href="https://bonuscloud.work/protocol"
            v-if="isZnWebsite"
          >用户协议</a>
        </div>
        
        <div
          class="footer-agree-link"
          target="_blank"
        >2020 BonusCloud © All rights reserved</div>
      </div>
      <!-- <div>2018 BonusCloud Foundation © All rights reserved</div> -->
    </div>
    <!-- </div> -->
    <div class="guide">
      <div class="guide-icon"></div>
      <a
        class="guid-link"
        :href="$t('guideLink')"
        target="_blank"
      >{{$t('guide')}}</a>
      <a
        class="guid-link"
        :href="$t('tutorialLink')"
        target="_blank"
      >{{$t('tutorial')}}</a>
      <a
        class="guid-link bonus-cursor"
        @click="showQrcode"
      >{{$t('qrcode')}}</a>
    </div>
  </div>
</template>


<script>
// import Footer from "@/components/Common/Footer.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    // Footer
  },
  computed: mapState({
    isZnWebsite: state => state.isZnWebsite,
  }),
  methods: {
    showQrcode() {
      this.$alert(
        '<img class="wechat-img" src="/qrcode.jpg"></img>',
        this.$t("qrcode"),
        {
          center: true,
          dangerouslyUseHTMLString: true
        }
      );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scopes lang="stylus">
.wechat-img {
  width: 80%;
}

.is-mobile {
  .foot-link {
    margin: 10px 0px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .foot-detail {
    width: auto;
  }
}

.line {
  margin-top: 20px;
  border-bottom: 1px solid #979797;
  opacity: 0.3;
  width: 100%;
  top: 62px;
}

.Footer {
  margin-top: 50px;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  color: #666;
  line-height: 16px;
}

.footer-agree-link {
  display: inline-block;
  padding: 10px 0 10px;
  z-index: 20000;
  position: relative;
}

.user-protocol a:hover {
  color: #fff;
}

.is-mobile .user-protocol {
  margin-top: 20px;
}

.foot-link {
  // border-top: 1px solid #ddd;
  width: 80%;
  margin: 40px auto 10px;
  display: flex;
  font-family: Arial, 'Helvetica Neue';
}

.foot-detail {
  width: 20%;
  text-align: left;
}

.foot-detail-title {
  font-weight: bold;
  display: block;
  margin: 10px 10px 20px;
  font-size: 16px;
  color: #fff;
}

.foot-detail-link {
  font-size: 14px;
  display: block;
  margin: 14px;
}

.footer-detail-email {
  margin-left: 10px;
}

.footer-detail-email img, .community-content img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.community-content {
  display: flex;
  margin: 10px 0 15px 10px;
}

.tech-community {
  margin-top: 50px;
}

.footer-club img {
  width: 16px;
  height: 16px;
}

.footer-tele {
  font-size: 14px;
}

.footer-tele span {
  margin: 0 4px;
}

.foot-annouce-icon img {
  display: inline-block;
  width: 18px;
  height: 18px;
  padding: 15px;
  margin: 0 auto;
}

.is-mobile {
  .guide {
    display: none;
  }

  .el-dialog--center {
    width: calc(100% - 20px) !important;
    margin: 0 10px;
  }
}

.guid-link
  display: block;
  font-size: 14px;
  color: #fff;

.guide
  border-right: 0;
  position: fixed;
  top: 60%;
  right: 0;
  background-color: #282557;
  padding: 10px;
  border-radius: 5px;
  text-align: center;

.guide-icon
  background-image: url('../../assets/guide.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  margin: 0 auto 5px;


</style>


<i18n>
{
  "zn": {
    "BonusCloud": "BonusCloud",
    "Whitepaper": "项目白皮书",
    "Highlight": "项目亮点", 
    "Advantage": "项目优势",
    "Member": "团队成员",
    "Partner": "合作伙伴",
    "Token": "Token",
    "Roadmap": "Roadmap",
    "Agreement": "用户协议",
    "Whitepaper_link": "https://bonuscloud.gitbook.io/help/bonuscloud-bai-pi-shu-v1.0", 
    "FAQ_link": "https://bonuscloud.gitbook.io/help/faq-chang-jian-wen-ti/faq-cn",
    "Get_BCode": "领取BCode",
    "Overview_link": "",
    "Revenue_Overview": " 收益总览",
    "Network_Task": "网络任务",
    "Computing_Task": "计算任务",
    "Withdrawal": "提现",
    "Hardware_List": "硬件列表",
    "Guide": "新手指南",
    "Guide_link": "https://bonuscloud.club/viewtopic.php?f=15&t=12",
    "Tutorial": "刷机教程导航",
    "Tutorial_link": "https://bonuscloud.club/viewtopic.php?f=15&t=2014",
    "Download": "下载中心",
    "tutorial": "刷机教程",
    "tutorialLink": "https://bonuscloud.club/viewtopic.php?f=15&t=2014",
    "guide": "新手入门",
    "guideLink": "https://bonuscloud.club/viewtopic.php?f=15&t=12",
    "qrcode": "微信公众号"
  },
  "en": {
    "BonusCloud": "BonusCloud",
    "Whitepaper": "Whitepaper", 
    "Highlight": "Highlight", 
    "Advantage": "Advantage",
    "Member": "Member",
    "Partner": "Partner",
    "Token": "Token",
    "Roadmap": "Roadmap",
    "Agreement": "Agreement",
    "Whitepaper_link": "https://bonuscloud.gitbook.io/help/bonuscloud-whitepaper-v-1.1-2018", 
    "FAQ_link": "https://bonuscloud.gitbook.io/help/faq-chang-jian-wen-ti/faq-en",
    "Get_BCode": "Get BCode",
    "BCode_link": "https://console.bonuscloud.io/",
    "Revenue_Overview": "Revenue Overview",
    "Network_Task": "Network Task",
    "Computing_Task": "Computing Task",
    "Withdrawal": "Withdrawal",
    "Hardware_List": "Hardware List",
    "Guide": "Guide For Beginners",
    "Guide_link": "https://bonuscloud.club/viewforum.php?f=18",
    "Tutorial": "Brush Tutorial",
    "Tutorial_link": "https://bonuscloud.club/viewforum.php?f=19",
    "Download": "Download",
    "tutorial": "Tutorial",
    "tutorialLink": "https://bonuscloud.club/viewforum.php?f=19",
    "guide": "Guide",
    "guideLink": "https://bonuscloud.club/viewtopic.php?f=18&t=17",
    "qrcode": "Wechat Qrcode"
  }
}
</i18n>
