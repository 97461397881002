<template>
    <!--  该功能只适用于父账号  -->
    <div v-if="!isFather" style="padding: 20px; text-align: center">无权限</div>
    <div v-else>
        <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px">
            <el-breadcrumb-item>资源管理</el-breadcrumb-item>
            <el-breadcrumb-item>矿机管理</el-breadcrumb-item>
            <el-breadcrumb-item>矿机数据总览</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card shadow="never" class="box-card">
            <h4>监控</h4>
            <span class="margin-left-20">日期：</span>
           <!-- <el-date-picker
              v-model="selectDate"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
              @change="search"
              :clearable="false"
            ></el-date-picker> -->
            
            <!-- 选择时间范围 -->
            <el-date-picker
              :picker-options="pickerOptions1"
              v-model="selectDate1"
              type="daterange"
              @change="search"
              :clearable="false"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
                
            <span class="margin-left-20">子账号：</span>
            <el-select
                    v-model="sonAccountId"
                    placeholder="请选择"
                    @change="search"
                    clearable
            >
                <el-option
                        v-for="item in sonAccountList"
                        :key="item.id"
                        :label="item.email"
                        :value="item.id"
                >
                </el-option>
            </el-select>
            <span class="margin-left-20">运营商：</span>
            <el-select
                    v-model="selectOperator"
                    placeholder="请选择"
                    @change="search"
                    clearable
            >
                <el-option value="电信">电信</el-option>
                <el-option value="移动">移动</el-option>
                <el-option value="联通">联通</el-option>
            </el-select>
            <div style="margin-top: 30px">
                <ve-line
                        v-if="chartData && Array.isArray(chartData.rows) && chartData.rows.length"
                        :data="chartData"
                        :extend="extend"
                ></ve-line>
            </div>
        </el-card>
    </div>
</template>

<script>
  import moment from 'moment';
  import fetch from '@/utils/fetch';
  import { baseUrl } from '@/utils/env';
  import { mapState } from "vuex";

  export default {
    name: 'DeviceDataView',
    data() {
      return {
        extend: {
          series:{
            itemStyle:{
              normal:{
                color:'#282557',//改变折点颜色
                lineStyle:{
                  color:'#282557', //改变折线颜色
                }
              }
            }, 
          }
        },
        sonAccountList: [],
        sonAccountId: '',
        selectDate: new Date(),
        selectDate1:new Date(),
        // pickerOptions: {
        //   disabledDate: time => {
        //     // 一个月
        //     return (
        //       time.getTime() <
        //       moment()
        //         .startOf("day")
        //         .valueOf() -
        //       24 * 1000 * 60 * 60 * 30 || time.getTime() > moment().valueOf()
        //     );
        //   },
        // },
         pickerOptions1:{
           //最多可选择60天
            disabledDate(time){
              let _now = Date.now(),
              twomonth = 60 * 24 * 60 * 60 * 1000,
              twomonthDays = _now - twomonth;
              return time.getTime() > _now || time.getTime() < twomonthDays;
            }
        }, 
        selectOperator: '',
        chartData: {
          columns: ['时间', '总带宽(Gbps)'],
          rows: []
        }
      }
    },
    computed: mapState({
      isFather: state => !state.accountInfo.level
    }),
    created() {
      this.getSonAccountList();
    },
    methods: {
      async getSonAccountList() {
        const res = await fetch(`${baseUrl}/api/agent/getSonAccountList`);
        if (res.code === 200) {
          this.sonAccountList = res.data || [];
          this.search();
        } else {
          this.$message({
            message: `查询失败：${res.message}`,
            type: 'error'
          })
        }
      },
      async search() {
        // const year = this.selectDate.getFullYear();
        // const month = this.selectDate.getMonth();
        // const date = this.selectDate.getDate();
        const year = this.selectDate1[0].getFullYear();
        const month = this.selectDate1[0].getMonth();
        const date = this.selectDate1[0].getDate();
        const dateAfter = new Date(year, month, date, 0, 0, 0).getTime();
        
        const year1 = this.selectDate1[1].getFullYear();
        const month1 = this.selectDate1[1].getMonth();
        const date1 = this.selectDate1[1].getDate();
        const dateBefore = new Date(year1, month1, date1, 23, 59, 59).getTime();
        
        const params = {
          dateAfter,
          dateBefore
        };
        
        if (this.sonAccountId) {
          params.slaveOwner = this.sonAccountId;
        }
        if (this.selectOperator) {
          params.operator = this.selectOperator;
        }
        const res = await fetch(`${baseUrl}/api/agent/bandwidthSummary`, params);
        if (res.code === 200) {
          if (!res.data || !res.data.length) {
            this.$message({
              message: '查询成功，但无数据',
              type: 'success'
            });
          }
          this.handleChartData(res.data || []);
        } else {
          this.$message({
            message: `查询失败：${res.message}`,
            type: 'error'
          });
        }
      },
      handleChartData(data) {
        const rows = data.map(d => ({
          '时间': moment(new Date(d.time)).format("YYYY-MM-DD HH:mm:ss"),
          '总带宽(Gbps)': Number((d.sum_bps / (1024 * 1024 * 1024)).toFixed(2))
        }));
        this.chartData = {
          columns: ['时间', '总带宽(Gbps)'],
          rows
        };
      },
    }
  };
</script>

<style scoped>

</style>
