/** 初级实名验证 */
<template>
  <div class="">
    <!-- 手机验证 -->
    <BasiceLayout
      title="手机验证"
      class="account-wrap margin-top-20"
    >
      <!-- 姓名 -->
      <div class=" item-wraps display-flex-center margin-20">
        <div class="left-item margin-right-20">姓名</div>
        <div class="right-item margin-left-20">
          <div
            class="right-text"
            v-if="type !== 'EDIT'"
          >{{user_name}}</div>
          <el-input
            v-if="type === 'EDIT'"
            v-model="user_name"
            placeholder="便于设备故障及时沟通，请填写真实信息"
          ></el-input>
        </div>
      </div>
      <!-- 微信号/QQ号 -->
      <div class="item-wraps display-flex-center margin-20">
        <div class="left-item margin-right-20">微信号/QQ号</div>
        <div class="right-item margin-left-20">
          <div
            v-if="type !== 'EDIT'"
            class="right-text"
          >
            {{contact}}
          </div>
          <el-input
            v-if="type === 'EDIT'"
            v-model="contact"
            placeholder="便于设备故障及时沟通，请填写真实信息"
          ></el-input>
        </div>
      </div>
      <!-- 手机 -->
      <div class="item-wraps display-flex-center margin-20">
        <div class="left-item margin-right-20">手机号</div>
        <div class="right-item margin-left-20">
          <div
            v-if="type !== 'EDIT'"
            class="right-text"
          >
            {{tel}}
          </div>
          <el-input
            v-if="type === 'EDIT'"
            v-model="tel"
            placeholder="便于设备故障及时沟通，请填写真实信息"
          ></el-input>
        </div>
      </div>
      <!-- 手机验证 -->
      <div class="item-wraps display-flex-center margin-20">
        <div class="left-item margin-right-20">手机验证码</div>
        <div class="right-item margin-left-20">
          <SendVerifyCode
            v-if="!telVerStatus || type === 'EDIT'"
            :iconType="noIcon"
            type="text"
            icon-type="emailCode"
            v-model="verifyCode"
            :tel="tel"
            @verifyCodeTip="verifyCodeTip"
            placeValue="发送手机验证码"
          />
          <div
            v-else
            class="right-text"
          >
            <el-tag type="success">已验证</el-tag>
          </div>
        </div>
      </div>
      <!-- 确定 -->
      <div class="contact-commit-wrap">
        <div
          v-if="type === 'EDIT'"
          class="button margin-top-20"
          @click="clickCommitContact"
        >确定</div>
        <div
          v-if="type !== 'EDIT'"
          class="button margin-top-20"
          @click="type = 'EDIT'"
        >修改</div>
      </div>
    </BasiceLayout>
  </div>
</template>

<script>
import AccountSetLayout from "@/components/AccountSet/AccountSetLayout.vue";
import BasiceLayout from "@/components/Common/BasicLayout.vue";
import { Message } from "element-ui";
import ImageCode from "@/components/ImageCode.vue";
import SendEmailCode from "@/components/SendEmailCode.vue";
import VueRecaptcha from "vue-recaptcha";
import Tips from "@/components/Common/Tips.vue";
import SendVerifyCode from "@/components/Common/SendVerifyCode.vue";
import fetch from '../../utils/fetch';
import { baseUrl } from '../../utils/env';

export default {
  name: "AccountLayout",
  components: {
    BasiceLayout,
    AccountSetLayout,
    ImageCode,
    SendEmailCode,
    VueRecaptcha,
    Tips,
    SendVerifyCode,
  },
  data() {
    return {
      contact: "",
      user_name: "",
      tel: "",
      type: "EDIT",
      verifyCode: "",
      noIcon: false,
      nameVerStatus: '',
      IDCard: '',
      telVerStatus: ""
    };
  },
  methods: {
    getContact() {
       return fetch(`${baseUrl}/api/contact/getContact`);
    },
    setContact(data) {
      return fetch(`${baseUrl}/api/contact/setContact`, data, 'POST');
    },
    verifyTel() {
      let { user_name, contact, tel, verifyCode } = this;

      if (!user_name || !contact || !tel || !verifyCode) {
        return Message({
          message: "请完整填写信息",
          type: "error",
        });
      } else {
        this.setContact({
          contact,
          user_name,
          tel,
          verifyCode,
          type: 1
        }).then((res) => {
          if (res.code === 200) {
            this.type === "WaitEdit";
            Message({
              message: "保存成功",
              type: "success",
            });
            this.updateContact();
          } else {
            Message({
              message: res.message || "网络失败",
              type: "error",
            });
          }
        });
      }
    },
    clickCommitContact() {
      let { user_name, contact, tel, verifyCode, IDCard } = this;

      if (!user_name || !contact || !tel || !verifyCode) {
        return Message({
          message: "请完整填写信息",
          type: "error",
        });
      } else {
        this.setContact({
          contact,
          user_name,
          tel,
          verifyCode,
          IDCard,
          type: 1
        }).then((res) => {
          if (res.code === 200) {
            this.type === "WaitEdit";
            Message({
              message: "保存成功",
              type: "success",
            });
            this.updateContact();
          } else {
            Message({
              message: res.message || "网络失败",
              type: "error",
            });
          }
        });
      }
    },
    // 获取最新联系方式
    updateContact() {
      this.getContact().then((res) => {
        if (res.data && res.data.contact) {
          let data = res.data;
          this.type = "WaitEdit";
          this.contact = data.contact;
          this.user_name = data.user_name;
          this.tel = data.tel;
          this.telVerStatus = data.telVerStatus;
          this.nameVerStatus = data.nameVerStatus;
          this.IDVerStatus = data.IDVerStatus;
          this.IDCard = data.IDCard;


          if (!data.telVerStatus) {
            Message({
              message: "根据实名认证要求，请完成手机号绑定。",
              type: "error",
            });
          }
        } else {
          Message({
            message: "根据实名认证要求，请完成手机号绑定。",
            type: "error",
          });
          this.type = "EDIT";
        }
      });
    },
    verifyCodeTip(error) {
      if (error && error.message) {
        Message({
          message: error.message,
          type: "error",
        });
      }
    },
  },
  created() {
    this.updateContact();
  },
  props: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus"></style>

